import { Box, Button, Paper } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux'
import { uploadCoverMachineType } from "../../store/Imager";
import { delCover, updateCord, uploadCoverMap } from "../../store/Parts";
import Modal from "../../components/Modal";
import DialogAction from "../../components/DialogAction";

const Imager = () => {

    const image = useSelector((state) => state.imager.image)
    const parts = useSelector((state) => state.imager.parts)
    const upload = useSelector((state) => state.imager.upload)
    const machineType = useSelector((state) => state.machineType.value)

    const [open, setOpen] = useState(false)

    const dispatch = useDispatch()

    const uploadHandler = (file) => {
        switch (upload.entity) {
            case 'parts':
                dispatch(uploadCoverMap(upload.id, file, upload.type_id))
                break;
            case 'machineType':
                dispatch(uploadCoverMachineType(upload.id, file))
                break;

            default:
                break;
        }
        console.log(upload)
    }

    const Point = ({ data }) => {

        const ref = useRef(null);
        const block = document.getElementById("imager")

        const [coords, setCoords] = useState([0, 0])

        useEffect(() => {
            if (typeof data.button_cords !== null) {
                const x = data.button_cords[0] * 760 / 100
                const y = data.button_cords[1] * 760 / 100
                setCoords([x, y])
            }
        }, [data.button_cords])

        const savePosition = (id, left, top) => {
            if (left < 0) left = 0
            if (top < 0) top = 0

            if (left > 760) left = 760
            if (top > 760) top = 760

            left = (left / 760) * 100
            top =(top / 760) * 100

            dispatch(updateCord(id, {
                cords: `${left},${top}`
            }, machineType.id))
            console.log("ID=", id, "LEFT %=", left, "TOP %=", top)
        }

        const handler = (e) => {
            function move(e) {
                setCoords([e.pageX - block.offsetLeft - 20 + "px", e.pageY - block.offsetTop - 70 + "px"])
            }

            document.onmousemove = function (e) {
                move(e)
            }

            ref.current.onmouseup = function (e) {
                document.onmousemove = null;
                ref.current.onmouseup = null

                savePosition(data.id, ref.current.offsetLeft, ref.current.offsetTop)
            }
        }
        return (
            <div ref={ref}
                onMouseDown={handler}
                data-id={data.id}
                style={{
                    position: 'absolute',
                    top: coords[1],
                    left: coords[0],
                    backgroundColor: '#5955b3',
                    color: "#ffffff",
                    paddingTop: 6,
                    paddingBottom: 8,
                    paddingLeft: 10,
                    paddingRight: 10,
                    cursor: 'pointer',
                    borderRadius: 4,
                    userSelect: 'none'
                }}>
                {data.title}
            </div>
        )
    }

    return (
        <Box>
            <Paper sx={{ width: 760, height: 760, position: 'relative', mb: 2 }} id="imager">
                <img style={{ width: '100%', height: '100%', objectFit: "contain" }} src={image} alt="Картинка" />
                {parts !== null ? parts.map(item => <Point key={item.id} data={item} />) : null}
            </Paper>
            {upload !== null ?
                <Box>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Button variant="contained" component="label" color='custom_purple'>
                            Загрузить изображение
                            <input hidden accept="image/*" multiple type="file" onChange={e => uploadHandler(e.target.files[0])} />
                        </Button>
                        {
                            image !== "http://shop.dstapi.ru/" && <Button onClick={() => {setOpen(true);console.log(upload)}} color="error">Удалить изображение</Button>
                        }
                    </Stack>
                </Box> : null}
            <Modal open={open} close={setOpen}>
                <DialogAction title="Удалить изображение" text="Вы действительно хотите удалить данное изображение?" open={open} setOpen={setOpen} agree="Удалить" action={() => dispatch(delCover(upload.id, upload.type_id, 'machineType'))} />
            </Modal>
        </Box>

    )
}

export default Imager