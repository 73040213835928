import { Box, Button, ButtonGroup, Typography } from "@mui/material"

const Confirm = ({ item = null, yes, no, yesLabel = null, yesColor = null }) => {
    return (
        <>
            <Box sx={{ p: 5 }}>
                {
                    item !== null && (
                        <Typography variant="h6" sx={{ mb: 2, mr: 3 }}>
                            Удалить "{item.title ? item.title : item.shop.title} - {item.shop.full_code}"
                        </Typography>
                    )
                }
                <ButtonGroup>
                    <Button onClick={yes} color={yesColor ? yesColor : "error"} variant="contained">{yesLabel ? yesLabel : "Удалить"}</Button>
                    <Button onClick={no} color="info" variant="contained">Отмена</Button>
                </ButtonGroup>
            </Box>
        </>
    )
}

export default Confirm