import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api"
import { toast } from 'react-toastify'

export const models = createSlice({
    name: 'models',
    initialState: {
        value: [],
        model: 0
    },
    reducers: {
        setModels: (state, action) => {
            state.value = action.payload.sort((a, b) => a.id - b.id)
        },
        clearModels: (state, action) => {
            state.value = []
        },
        setModelId: (state, action) => {
            state.model = action.payload
        }
    },
})

export const { setModels, clearModels, setModelId } = models.actions

export const getModels = (parent) => async (dispatch) => {
    try {
        const response = await api.get(`/api/v1/models?type_id=${parent.id}`, {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setModels(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export default models.reducer