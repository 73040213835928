import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setPart } from "../../store/Part";
import SubPartsMap from "./SubPartsMap";
import {
  setImagerImage,
  setImagerParts,
  setImagerUpload,
} from "../../store/Imager";
import { setSubpart } from "../../store/SubPart";
import { setSubsubpart } from "../../store/SubSubPart";
import { setDetails } from "../../store/Details";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import EditPartForm from "./EditPartForm";
import AddPartForm from "./AddPartForm";
import { getModelGroup } from "../../store/ModelGroup";
import { useLocation, useSearchParams } from "react-router-dom";

const PartsMap = ({ parts }) => {
  const dispatch = useDispatch();
  const part = useSelector((state) => state.part.value);
  const subpart = useSelector((state) => state.subpart.value);
  const machineType = useSelector((state) => state.machineType.value);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  const [itemEdit, setItemEdit] = useState(null);

  useEffect(() => {
    if (parts.length === 0) return;
    const query = new URLSearchParams(location.search);
    if (query.has("part")) {
      const qPart = parts.find(
        (item) => item.id === parseInt(query.get("part"))
      );

      if (typeof qPart !== "undefined") {
        handlerPart(
          qPart,
          !query.has("subpart") || subpart === null ? true : false
        );
      } else {
        handlerPart(parts[0]);
      }
    } else {
      if (part !== null) {
        const item = parts.find((item) => item.id === part.id);
        if (typeof item !== "undefined") {
          handlerPart(
            item,
            !query.has("subpart") || subpart === null ? true : false
          );
        } else {
          handlerPart(parts[0]);
        }
      }
    }
  }, [parts]);

  const handleClose = () => {
    setOpen(false);
  };

  const handlerEdit = (e, item) => {
    e.stopPropagation();
    setOpen(true);
    setItemEdit(item);
  };

  const handlerPart = (item, refreshParams = true, isClick = false) => {
    if (isClick && part && item.id === part.id) {
      dispatch(setPart(null));

      if (machineType.image !== null) {
        dispatch(setImagerImage(machineType.image));
      } else {
        dispatch(setImagerImage("noimg.jpg"));
      }

      dispatch(setImagerParts(parts));

      dispatch(
        setImagerUpload({
          entity: "machineType",
          id: machineType.id,
        })
      );
      setSearchParams({ machine_type: machineType.id });
    } else {
      dispatch(setPart(item));
      if (refreshParams) {
        if (item.image !== null) {
          dispatch(setImagerImage(item.image));
        } else {
          dispatch(setImagerImage("noimg.jpg"));
        }

        dispatch(setImagerParts(item.children));

        dispatch(
          setImagerUpload({
            entity: "parts",
            id: item.id,
            type_id: machineType.id,
          })
        );
        setSearchParams({ machine_type: machineType.id, part: item.id });
      }
    }

    dispatch(setSubpart(null));
    dispatch(setSubsubpart(0));
    dispatch(setDetails(null));
  };

  const [modal, setModal] = useState(false);

  const handlerModalClose = () => {
    setModal(false);
  };

  return (
    <>
      <List>
        {parts !== "load" &&
          parts.map((item, i) => (
            <Box key={i}>
              <ListItem
                sx={{ borderLeft: "1px solid #dedede" }}
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="comment"
                    onClick={(e) => handlerEdit(e, item)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                }
                onClick={() => handlerPart(item, true, true)}
              >
                <ListItemButton>
                  <ListItemText
                    primary={
                      <Box display={"flex"}>
                        <Box sx={{ mr: 1 }}>{item.code}.</Box>
                        <Box
                          sx={{
                            cursor: "pointer",
                            fontWeight: part && item.id === part.id ? 600 : 400,
                          }}
                        >
                          {item.title}
                        </Box>
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
              {part && item.id === part.id ? (
                <SubPartsMap items={item.children} parentCode={item.code} />
              ) : null}
            </Box>
          ))}

        <ListItem sx={{ borderLeft: "1px solid #dedede" }} disablePadding>
          <ListItemButton onClick={() => setModal(true)}>
            <AddCircleOutlineIcon
              color="primary"
              sx={{ mr: 1 }}
              fontSize="small"
            />
            <ListItemText secondary="Добавить блок" />
          </ListItemButton>
        </ListItem>
      </List>

      <Modal open={open} close={handleClose}>
        <EditPartForm item={itemEdit} close={handleClose} />
      </Modal>

      <Modal open={modal} close={handlerModalClose}>
        <AddPartForm parent={null} />
      </Modal>
    </>
  );
};

export default PartsMap;
