import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {
  getVariantItems,
  removeVariantItem,
  updateVariantItem,
} from "../../store/VariantItems";
import Modal from "../../components/Modal";
import Confirm from "../../components/Modal/Confirm";
import AddItemForm from "./AddItemForm";
import AddOtherItemForm from "./AddOtherItemForm";
import { setSearch, setSearchItem } from "../../store/SearchItem";
import VariantImage from "./VariantImage";
import EditIcon from "@mui/icons-material/Edit";
import Preloaderr from "../../components/Preloaderr";
import { formatPrice } from "../../utils/price";

const VariantDetails = () => {
  const dispatch = useDispatch();

  const variant = useSelector((state) => state.variant.value);
  const variantItems = useSelector((state) => state.variantItems.value);
  const details = useSelector((state) => state.details.value);

  const [workItem, setWorkItem] = useState(null);
  const [modalDel, setModalDel] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelItem, setModalDelItem] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalAddOther, setModalAddOther] = useState(false);

  const handlerDelButton = (itemID) => {
    setWorkItem(itemID);
    setModalDel(true);
  };

  const handlerEditButton = (item) => {
    setWorkItem(item);
    setModalEdit(true);
  };

  const handlerCloseModals = () => {
    setModalDel(false);
    setModalAdd(false);
    setModalAddOther(false);
    setWorkItem(null);
    dispatch(setSearchItem(null));
    dispatch(setSearch(false));
    setModalEdit(false);
  };

  const confirmDelete = () => {
    dispatch(removeVariantItem(workItem, variant.id));
    handlerCloseModals();
  };

  useEffect(() => {
    dispatch(getVariantItems(variant.id));
  }, []);

  const handlerAdd = (detail) => {
    setModalAdd(true);
    setWorkItem(detail);
  };

  if (variantItems === null || details === null) return <Preloaderr />;

  return (
    <>
      <Box display={"flex"} sx={{ flexWrap: "wrap" }}>
        <VariantImage />
        <Box>
          <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
            <Table
              sx={{ width: 630 }}
              size="small"
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>Код</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Артикул</TableCell>
                  <TableCell>Крат-ть</TableCell>
                  <TableCell>Стоимость</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(typeof variantItems !== "undefined") & (variantItems !== null)
                  ? variantItems.map((row) => (
                      <TableRow key={row.full_code}>
                        <TableCell component="th" scope="row">
                          {row.nomer}
                        </TableCell>
                        <TableCell>{row.shop.full_code}</TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {row.title ? row.title : row.shop.title}
                          </Typography>
                        </TableCell>
                        <TableCell>{row.e_com.article}</TableCell>
                        <TableCell>
                          {row.rate !== null ? row.rate : row.e_com.rate}
                        </TableCell>
                        <TableCell>{formatPrice(row.e_com.price)}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              handlerEditButton(row);
                            }}
                            size="small"
                          >
                            <EditIcon fontSize="small" color="custom_purple" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              setModalDelItem(row);
                              handlerDelButton(row.id);
                            }}
                            size="small"
                          >
                            <HighlightOffIcon fontSize="small" color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
            <Table
              sx={{ width: 630 }}
              size="small"
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>Код</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Артикул</TableCell>
                  <TableCell>Кратность</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.map((row, i) => (
                  <TableRow
                    key={row.full_code + i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handlerAdd(row)}
                        size="small"
                      >
                        <ControlPointIcon
                          fontSize="small"
                          color="custom_purple"
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.full_code}</TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {row.titleThis ? row.titleThis : row.title}
                      </Typography>
                      {row.titleThis ? (
                        <Typography variant="caption">{row.title}</Typography>
                      ) : null}
                    </TableCell>
                    <TableCell>{row.article}</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ p: 3 }}>
            <Button
              color="custom_purple"
              variant="contained"
              onClick={() => setModalAddOther(true)}
            >
              Добавить
            </Button>
          </Box>
        </Box>
      </Box>

      <Modal open={modalDel} close={handlerCloseModals}>
        <Confirm
          item={modalDelItem}
          yes={confirmDelete}
          no={handlerCloseModals}
        />
      </Modal>

      <Modal open={modalEdit} close={handlerCloseModals}>
        <Box sx={{ p: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, mr: 3 }}>
            Редактировать
          </Typography>
          <FormControl
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              const title = e.target.elements.title.value;
              dispatch(
                updateVariantItem(workItem.id, { title }, workItem.variant_id)
              );
            }}
          >
            <TextField
              required
              label="Название"
              variant="outlined"
              type="text"
              margin="dense"
              name="title"
              defaultValue={
                workItem !== null
                  ? workItem.title !== null && workItem.title != ""
                    ? workItem.title
                    : workItem.e_com.name
                  : ""
              }
            />
            <Button
              color="custom_purple"
              sx={{ mt: 2 }}
              type="submit"
              variant="contained"
            >
              Обновить
            </Button>
          </FormControl>
          <FormControl
            sx={{
              ml: 2,
            }}
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              const rate = e.target.elements.rate.value;
              dispatch(
                updateVariantItem(workItem.id, { rate }, workItem.variant_id)
              );
            }}
          >
            <TextField
              required
              label="Кратность"
              variant="outlined"
              type="text"
              margin="dense"
              name="rate"
              defaultValue={
                workItem !== null
                  ? workItem.rate !== null
                    ? workItem.rate
                    : workItem.e_com.rate
                  : 1
              }
            />
            <Button
              color="custom_purple"
              sx={{ mt: 2 }}
              type="submit"
              variant="contained"
            >
              Обновить
            </Button>
          </FormControl>
          <Box>
            <FormControl
              sx={{
                mt: 2,
              }}
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                const nomer = e.target.elements.nomer.value;
                dispatch(
                  updateVariantItem(workItem.id, { nomer }, workItem.variant_id)
                );
              }}
            >
              <TextField
                required
                label="Номер"
                variant="outlined"
                type="text"
                margin="dense"
                name="nomer"
                defaultValue={
                  workItem !== null
                    ? workItem.nomer !== null
                      ? workItem.nomer
                      : workItem.e_com.nomer
                    : 1
                }
              />
              <Button
                color="custom_purple"
                sx={{ mt: 2 }}
                type="submit"
                variant="contained"
              >
                Обновить
              </Button>
            </FormControl>
          </Box>
          <FormControl
            sx={{
              mt: 2,
            }}
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              const build = e.target.elements.build.checked;
              const shop = e.target.elements.shop.checked;
              const chassis = e.target.elements.chassis.checked;
              const wearable_products =
                e.target.elements.wearable_products.checked;

              dispatch(
                updateVariantItem(
                  workItem.id,
                  {
                    build,
                    shop,
                    chassis,
                    wearable_products,
                  },
                  workItem.variant_id
                )
              );
            }}
          >
            {workItem !== null &&
              workItem.options !== null &&
              typeof workItem.options !== "undefined" && (
                <FormGroup>
                  <FormControlLabel
                    name="build"
                    control={
                      <Checkbox
                        color="custom_purple"
                        defaultChecked={parseFloat(workItem.options.build)}
                      />
                    }
                    label="Собственное производство"
                  />
                  <FormControlLabel
                    name="shop"
                    control={
                      <Checkbox
                        color="custom_purple"
                        defaultChecked={parseFloat(workItem.options.shop)}
                      />
                    }
                    label="Покупные изделия"
                  />
                  <FormControlLabel
                    name="chassis"
                    control={
                      <Checkbox
                        color="custom_purple"
                        defaultChecked={parseFloat(workItem.options.chassis)}
                      />
                    }
                    label="Ходовая часть"
                  />
                  <FormControlLabel
                    name="wearable_products"
                    control={
                      <Checkbox
                        color="custom_purple"
                        defaultChecked={parseFloat(
                          workItem.options.wearable_products
                        )}
                      />
                    }
                    label="Быстроизнашиваемые изделия"
                  />
                </FormGroup>
              )}
            <Button
              color="custom_purple"
              sx={{ mt: 2 }}
              type="submit"
              variant="contained"
            >
              Сохранить
            </Button>
          </FormControl>
        </Box>
      </Modal>

      {workItem && modalAdd ? (
        <Modal open={modalAdd} close={handlerCloseModals}>
          <AddItemForm detail={workItem} close={handlerCloseModals} />
        </Modal>
      ) : null}

      {modalAddOther ? (
        <Modal open={modalAddOther} close={handlerCloseModals}>
          <AddOtherItemForm close={handlerCloseModals} />
        </Modal>
      ) : null}
    </>
  );
};

export default VariantDetails;
