import { Typography } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getVariant } from "../../store/Variant"
import VarianHeader from "./VarianHeader"
import VariantDetails from "./VariantDetails"

const Variant = () => {

    const { id } = useParams()
    const dispatch = useDispatch()

    const variant = useSelector((state) => state.variant.value)

    useEffect(() => {
        dispatch(getVariant(id))
    }, [id, dispatch])

    if (variant === null) return null

    return (
        <>
            <VarianHeader />
            <Typography variant="h4" gutterBottom sx={{ mt: 1 }}>
                {variant.title}
            </Typography>
            <VariantDetails />
        </>
    )
}

export default Variant