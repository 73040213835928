import { Button, Box, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, TextField } from '@mui/material'
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CloseIcon from '@mui/icons-material/Close';
import { addVariant } from '../../store/Variants';
import { toast } from 'react-toastify';

const AddVariantForm = ({ close }) => {
    const dispatch = useDispatch()

    const model = useSelector((state) => state.models.model)
    const subsubpart = useSelector((state) => state.subsubpart.value)

    const [title, setTitle] = useState('')
    const [article, setArticle] = useState('')
    const [comment, setComment] = useState('')
    const [files, setFiles] = useState([])

    const submit = () => {
        if (title === '') {
            toast.error('Не заполнен заголовок')
        }
        dispatch(addVariant({
            title: title,
            article: article,
            files: files,
            comment: comment,
            map_id: subsubpart.id,
            model_id: model
        }))
        setTitle('')
        setArticle('')
        setComment('')
        setFiles([])
        close(false)
    }

    return (
        <>
            <DialogTitle>
                Добавить сборку
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <TextField
                        margin="dense"
                        label="Заголовок"
                        required
                        variant="outlined"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Артикул"
                        inputProps={{
                            type: 'number'
                        }}
                        variant="outlined"
                        value={article}
                        onChange={e => setArticle(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Коментарий"
                        multiline
                        variant="outlined"
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                    />
                    <Button variant="contained" component="label" sx={{ mt: '8px' }} color='custom_purple'>
                        Загрузить изображение
                        <input hidden accept="image/*" type="file" onChange={e => setFiles(e.target.files['0'])} />
                    </Button>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button color='custom_purple' onClick={submit} variant="contained">Добавить</Button>
            </DialogActions>
        </>
    )
}

export default AddVariantForm