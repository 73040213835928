import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setDetails } from "../../store/Details";
import {
  setImagerImage,
  setImagerParts,
  setImagerUpload,
} from "../../store/Imager";
import { setMachineType } from "../../store/MachineType";
import { getMachineTypes } from "../../store/MachineTypes";
import { setPart } from "../../store/Part";
import { getParts, setParts } from "../../store/Parts";
import { setSubpart } from "../../store/SubPart";
import { setSubsubpart } from "../../store/SubSubPart";
import { useLocation, useSearchParams } from "react-router-dom";
import Preloaderr from "../../components/Preloaderr";

const TypeSelector = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const machineTypes = useSelector((state) => state.machineTypes.value);
  const machineType = useSelector((state) => state.machineType.value);
  const parts = useSelector((state) => state.parts.value);
  const image = useSelector((state) => state.imager.image);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (machineTypes.length === 0) {
      dispatch(getMachineTypes());
    }
  }, []);

  const handlerType = (type, refreshParams = true) => {
    if (refreshParams) setSearchParams({ machine_type: type.id });
    dispatch(setParts([]));
    dispatch(setImagerParts([]));
    dispatch(setMachineType(type));
    dispatch(getParts(type.id));
    dispatch(setPart(null));
    dispatch(setSubpart(null));
    dispatch(setSubsubpart(null));
    dispatch(setDetails(null));

    if (type.image !== null) {
      dispatch(setImagerImage(type.image));
    } else {
      dispatch(setImagerImage("noimg.jpg"));
    }

    dispatch(
      setImagerUpload({
        entity: "machineType",
        id: type.id,
      })
    );
  };

  useEffect(() => {
    if (machineTypes.length > 0) {
      const query = new URLSearchParams(location.search);
      if (query.has("machine_type")) {
        const type = machineTypes.find(
          (item) => item.id === parseInt(query.get("machine_type"))
        );

        if (typeof type !== "undefined") {
          handlerType(type, false);
        } else {
          handlerType(machineTypes[0]);
        }
      } else {
        if (machineType !== null) {
          handlerType(machineType, false);
        } else {
          handlerType(machineTypes[0]);
        }
      }
    }
  }, [machineTypes]);

  if (image === "load" || parts === "load" || machineType === null)
    return <Preloaderr />;
  return (
    <Box
      display={"flex"}
      sx={{
        overflowX: "scroll",
      }}
    >
      <Box>
        {machineTypes.map((item) => (
          <Button
            onClick={() => handlerType(item)}
            sx={{ mr: 2 }}
            variant={item.id === machineType.id ? "contained" : "outlined"}
            key={item.id}
            color="custom_purple"
          >
            {item.title}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default TypeSelector;
