import { Box, Link } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getDetails } from "../../store/Details"
import { setImagerImage, setImagerParts, setImagerUpload } from "../../store/Imager"
import { setMachineType } from "../../store/MachineType"
import { getMachineTypes } from "../../store/MachineTypes"
import { setPart } from "../../store/Part"
import { getParts } from "../../store/Parts"
import { setSubpart } from "../../store/SubPart"
import { setSubsubpart } from "../../store/SubSubPart"

const VarianHeader = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const machineTypes = useSelector((state) => state.machineTypes.value)
    const machineType = useSelector((state) => state.machineType.value)
    const parts = useSelector((state) => state.parts.value)
    const part = useSelector((state) => state.part.value)
    const subpart = useSelector((state) => state.subpart.value)
    const subsubpart = useSelector((state) => state.subsubpart.value)
    const variant = useSelector((state) => state.variant.value)

    useEffect(() => {
        if (machineTypes.length === 0) {
            dispatch(getMachineTypes())
        } else {
            const mt = machineTypes.find(item => item.id === variant.machine_type_id)
            dispatch(setMachineType(mt))
        }
    }, [variant, machineTypes])

    useEffect(() => {
        if (machineType) {
            if (parts.length === 0) {
                dispatch(getParts(machineType.id))
            } else {
                const find = parts.find(item => item.id === variant.part_id)
                const findSub = find.children.find(item => item.id === variant.subPart_id)
                const findSubSub = findSub.children.find(item => item.id === variant.subSubPart_id)
                dispatch(setPart(find))
                dispatch(setSubpart(findSub))
                dispatch(setSubsubpart(findSubSub))
                dispatch(getDetails(findSubSub.id))
                dispatch(setImagerImage(null))
                dispatch(setImagerParts([]))
                dispatch(setImagerUpload(null))
            }
        }
    }, [machineType, parts])


    if (machineType && part && subpart && subsubpart) {
        return (
            <Box display={'flex'}>
                <Box sx={{ mr: 2 }}>
                    {/* <Link sx={{ cursor: 'pointer', color: '#5955b3' }} onClick={() => navigate('/vars')}>Назад</Link> */}
                </Box>
                <Box>
                    {machineType.title}. {part.title}. {subpart.title}. {subsubpart.title}
                </Box>
            </Box>
        )
    } else {
        return null
    }
}

export default VarianHeader