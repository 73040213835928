import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api"
import { toast } from 'react-toastify'
import { getMachineTypes } from './MachineTypes'

export const imager = createSlice({
    name: 'imager',
    initialState: {
        image: 'load',
        parts: [],
        upload: null
    },
    reducers: {
        setImagerImage: (state, action) => {
            state.image = action.payload
        },
        setImagerParts: (state, action) => {
            state.parts = action.payload
        },
        setImagerUpload: (state, action) => {
            state.upload = action.payload
        },
    },
})

export const { setImagerImage, setImagerParts, setImagerUpload } = imager.actions

export const uploadCoverMachineType = (id, files) => async (dispatch, getState) => {
    try {
        const data = new FormData()
        data.append('files', files)

        const response = await toast.promise(
            api.post(`/api/v1/types/${id}/cover`, data),
            {
                pending: 'Загрузка',
                success: 'Изображение успешно загружено 👌',
                error: 'Не удалось выполнить операцию 🤯'
            }
        );

        if (response.status === 201) {
            dispatch(getMachineTypes())
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export default imager.reducer