import { createSlice } from "@reduxjs/toolkit";
import api from "../services/api";
import { toast } from "react-toastify";

export const variants = createSlice({
  name: "variants",
  initialState: {
    value: null,
  },
  reducers: {
    setVariants: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setVariants } = variants.actions;

export const getVariants = (parent, model) => async (dispatch) => {
  try {
    const response = await api.get(
      `/api/v1/shop/installation?map_id=${parent}&model_id=${model}`,
      {}
    );

    if (response.status === 200) {
      const json = await response.data;

      json.map((item) => {
        if (item.image !== null)
          item.image = "http://shop.dstapi.ru/" + item.image;

        return item;
      });

      dispatch(setVariants(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const addVariant = (data) => async (dispatch) => {
  try {
    const fdata = new FormData();
    fdata.append("title", data.title);
    fdata.append("article", data.article);
    fdata.append("files", data.files);
    fdata.append("comment", data.comment);
    fdata.append("map_id", data.map_id);
    fdata.append("model_id", data.model_id);

    const response = await toast.promise(
      api.post(`/api/v1/shop/installation`, fdata),
      {
        pending: "Загрузка",
        success: "Сборка успешно добавлена 👌",
        error: "Не удалось добавить сборку 🤯",
      }
    );

    if (response.status === 201) {
      dispatch(getVariants(data.map_id, data.model_id));
    }
  } catch (e) {
    toast.error("Не удалось выполнить операцию");
    console.error(e);
  }
};

export const linkVariant = (id, data, parent, model) => async (dispatch) => {
  try {
    const response = await api.post(
      `/api/v1/shop/installation/${id}/model`,
      data
    );

    if (response.status === 201) {
      dispatch(getVariants(parent, model));
      toast.success("Прикреплено");
    }
  } catch (e) {
    toast.error("Не удалось выполнить операцию");
    console.error(e);
  }
};

export const unlinkVariant = (id, parent, model) => async (dispatch) => {
  try {
    const response = await api.delete(`/api/v1/shop/installation/model/${id}`);

    if (response.status === 200) {
      dispatch(getVariants(parent, model));
      toast.success("Откреплен");
    }
  } catch (e) {
    toast.error("Не удалось выполнить операцию");
    console.error(e);
  }
};

export const updateVariant = (id, data, parent, model) => async (dispatch) => {
  try {
    const response = await api.put(`/api/v1/shop/installation/${id}`, data);

    if (response.status === 200) {
      dispatch(getVariants(parent, model));
      toast.success("Данные обновлены");
    }
  } catch (e) {
    toast.error("Не удалось выполнить операцию");
    console.error(e);
  }
};

export const deleteVariant = (id, parent, model) => async (dispatch) => {
  try {
    const response = await api.delete(`/api/v1/shop/installation/${id}`);

    if (response.status === 200) {
      dispatch(getVariants(parent, model));
      toast.success("Сборка успешно удалена");
    }
  } catch (e) {
    toast.error("Не удалось выполнить операцию");
    console.error(e);
  }
};

export const copyVariant = (id, parent, model) => async (dispatch) => {
  try {
    const response = await api.get(
      `/api/v1/shop/installation/${id}/copy?model_id=${model}`
    );

    if (response.status === 200) {
      dispatch(getVariants(parent, model));
      toast.success("Сборка успешно скопирована");
    }
  } catch (e) {
    console.error(e);
  }
};

export default variants.reducer;
