import { Fab, Grid, Paper } from "@mui/material"
import Modal from "../../components/Modal"
import AddVariantForm from "./AddVariantForm"
import ModelsSelector from "./ModelsSelector"
import PartSelector from "./PartSelector"
import VariantsList from "./VariantsList"
import { useState } from 'react'
import { useSelector } from "react-redux"
import AddIcon from '@mui/icons-material/Add';

const Variants = () => {
    const [open, setOpen] = useState(false)
    const subsubpart = useSelector((state) => state.subsubpart.value)


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Paper sx={{ p: 3, mb: 3 }}>
                        <PartSelector />
                    </Paper>
                    <Paper sx={{ p: 3 }}>
                        <ModelsSelector />
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <VariantsList />
                </Grid>
            </Grid>
            {
                subsubpart !== null ? (
                    <Fab color="primary" aria-label="add" sx={{
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px'
                    }}
                        onClick={() => setOpen(true)}
                        color="custom_purple"
                    >
                        <AddIcon />
                    </Fab>
                ) : null
            }
            <Modal open={open} close={setOpen}>
                <AddVariantForm close={setOpen} />
            </Modal>
        </>
    )
}

export default Variants