import { useEffect, useState } from "react"
import "./style.css"
import { Box, Typography } from "@mui/material"

const Preloaderr = () => {
    const [time, setTimer] = useState(10)

    useEffect(() => {
        const timers = time > 0 && setInterval(() => setTimer(time - 1), 1000);

        return () => clearInterval(timers);
    }, [time])

    return (
        <>
            {
                time === 0 ? (
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography color="#5955b3">Не удалось загрузить данные или они отсутствуют</Typography>
                    </Box>
                ) : (
                    <div className="preloader">
                        <div className="cssload-container">
                            <div className="cssload-speeding-wheel"></div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Preloaderr