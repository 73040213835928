import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  copyVariant,
  deleteVariant,
  getVariants,
  setVariants,
} from "../../store/Variants";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Modal from "../../components/Modal";
import Confirm from "../../components/Modal/Confirm";
import { useNavigate } from "react-router-dom";
import { setVariant } from "../../store/Variant";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditForm from "./EditForm";
import DialogAction from "../../components/DialogAction";
import Preloaderr from "../../components/Preloaderr";

const VariantsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const variants = useSelector((state) => state.variants.value);
  const subsubpart = useSelector((state) => state.subsubpart.value);
  const model = useSelector((state) => state.models.model);

  useEffect(() => {
    dispatch(setVariants(null));
    dispatch(setVariant(null));
    if (subsubpart !== null && model !== null) {
      dispatch(getVariants(subsubpart.id, model));
    }
  }, [subsubpart, model]);

  const [workItem, setWorkItem] = useState(null);
  const [modalDel, setModalDel] = useState(false);
  const [modalCopy, setModalCopy] = useState(false);
  const [edit, setEdit] = useState(false);
  const [anchorElContext, setAnchorElContext] = useState(null);

  const context = Boolean(anchorElContext);

  const handleClickContext = (event) => {
    setAnchorElContext(event.currentTarget);
  };

  const handleCloseContext = () => {
    setAnchorElContext(null);
  };

  const handlerDelButton = (itemID) => {
    setWorkItem(itemID);
    setModalDel(true);
  };

  const handlerEditButton = (item) => {
    setWorkItem(item);
    setEdit(true);
  };

  const handlerCloseModalDel = () => {
    setModalDel(false);
    setWorkItem(null);
  };

  const confirmDelete = () => {
    dispatch(deleteVariant(workItem, subsubpart.id, model));
    handlerCloseModalDel();
  };

  const handlerCloseModalCopy = () => {
    setModalCopy(false);
    setWorkItem(null);
  };

  const handlerCopyItem = (itemID) => {
    setWorkItem(itemID);
    setModalCopy(true);
  };

  const confirmCopy = () => {
    dispatch(copyVariant(workItem, subsubpart.id, model));
    setModalCopy(false);
    setWorkItem(null);
  };

  const formatArtic = (article) => {
    let articleTrue = article;
    if (article.length > 7) articleTrue = article.substr(-7);

    if (article.length < 7) {
      const count = 7 - article.length;
      articleTrue = "0".repeat(count) + articleTrue;
    }

    return articleTrue;
  };

  const Variant = ({ item }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <Card sx={{ width: 300, mr: 2, mb: 2, position: "relative" }}>
        <CardMedia sx={{ height: 300 }} image={item.image} title={item.title} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {item.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {item.comment}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatArtic(`${item.article}`) ?? "---"}
          </Typography>
          {item.hidden ? (
            <Typography variant="body2" color="primary">
              Скрыто
            </Typography>
          ) : null}
        </CardContent>
        <CardActions>
          <Button
            color="custom_purple"
            size="small"
            onClick={() => navigate(`/vars/${item.id}`)}
          >
            Открыть
          </Button>
        </CardActions>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-haspopup="true"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={() => handlerCopyItem(item.id)}>Копия</MenuItem>
          <MenuItem onClick={() => handlerDelButton(item.id)}>Удалить</MenuItem>
          <MenuItem onClick={() => handlerEditButton(item)}>Параметры</MenuItem>
        </Menu>
      </Card>
    );
  };

  if (variants === null) return <Preloaderr />;

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ display: "flex" }}>
        Сборки
        <IconButton
          sx={{ ml: "auto" }}
          aria-label="more"
          id="long-button"
          aria-haspopup="true"
          aria-controls={context ? "long-menu" : undefined}
          aria-expanded={context ? "true" : undefined}
          onClick={handleClickContext}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorElContext}
          open={context}
          onClose={handleCloseContext}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem>Сортировать</MenuItem>
        </Menu>
      </Typography>
      <Box display={"flex"} sx={{ flexWrap: "wrap" }}>
        {variants.map((variant) => (
          <Variant key={variant.id} item={variant} />
        ))}
      </Box>

      <Modal open={modalDel} close={handlerCloseModalDel}>
        <DialogAction
          title={"Удаление сборки"}
          text={
            "Вы уверены что хотите удалить сборку? Это необратимый процесс который приведет к полному удалению сборки во всех моделях к которым она привязана, а так же удалит все ее содержимое!"
          }
          agree="Удалить"
          open={modalDel}
          setOpen={handlerCloseModalDel}
          action={confirmDelete}
          timer={true}
        />
      </Modal>

      <Modal open={modalCopy} close={handlerCloseModalCopy}>
        <DialogAction
          title={"Копирование сборки"}
          text={"Вы уверенны что ходите копировать сборку?"}
          agree="Копировать"
          open={modalCopy}
          setOpen={handlerCloseModalCopy}
          action={confirmCopy}
          timer={false}
        />
      </Modal>

      <Modal open={edit} close={setEdit}>
        <EditForm setOpen={setEdit} item_cur={workItem} />
      </Modal>
    </>
  );
};

export default VariantsList;
