import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api"
import jwt from 'jwt-decode'
import { toast } from 'react-toastify'

export const Auth = createSlice({
    name: 'Auth',
    initialState: {
        auth: null,
        email: "",
        password: "",
        token: localStorage.getItem("token"),
        user: [],
        companys: JSON.parse(localStorage.getItem("companys"))
    },
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setPassword: (state, action) => {
            state.password = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setAuth: (state, action) => {
            state.auth = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setCompanys: (state, action) => {
            state.companys = action.payload
        },
    },
})

export const { setEmail, setPassword, setToken, setAuth, setUser, setCompanys } = Auth.actions

export const authUser = () => async (dispatch, getState) => {
    const email = getState().Auth.email
    const password = getState().Auth.password

    try {
        const response = await api.post('/api/auth/login', {
            email: email,
            password: password
        })

        if (response.status === 200) {
            const { access_token, expires_in } = await response.data

            const companys = JSON.stringify(jwt(access_token).companys)

            localStorage.setItem("token", access_token)
            localStorage.setItem("expires_in", expires_in)
            localStorage.setItem("companys", companys)

            dispatch(setCompanys(companys))
            dispatch(setToken(access_token))
            dispatch(setAuth(true))
        }

    } catch (e) {
        const message = e.response.data.message
        switch (message) {
            case 'invalid username or password':
                toast.error('Неверный email или пароль')
                break;

            case 'empty required inputs':
                toast.error('Не все обязательные поля заполнены')
                break;
            default:
                toast.error('Непредвиденная ошибка сервера')
                break;
        }
    }
}

export const logOut = () => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/auth/logout', {})

        if (response.status === 200) {
            localStorage.setItem("token", '')

            dispatch(setToken(''))
            dispatch(setAuth(false))
            dispatch(setUser([]))
        }

    } catch (e) {
        console.error(e)
    }
}

export const getUser = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/user', {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setUser(json))
            dispatch(setAuth(true))
        }

    } catch (e) {
        console.error(e)
    }
}

let refreshTokenRequest = null
export const getAccessToken = () => async (dispatch, getState) => {
    try {
        const accessToken = getState().Auth.token

        if (!accessToken || isTokenExpired(accessToken)) {
            if (refreshTokenRequest === null) {
                refreshTokenRequest = api.post('/api/auth/refresh-tokens', [], {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
            }

            const res = await refreshTokenRequest
            refreshTokenRequest = null

            dispatch(setToken(res.data.access_token))
            localStorage.setItem("token", res.data.access_token)


            return res.data.access_token
        }

        return accessToken
    } catch (e) {
        console.error(e)
        dispatch(setAuth(false))
        return null
    }
}

export const isTokenExpired = (token) => {
    if (!token) {
        return true
    }

    try {
        const tokenInfo = token.split('.')[1]
        const tokenInfoDecoded = window.atob(tokenInfo)
        const { exp, iat } = JSON.parse(tokenInfoDecoded)


        const tokenLeftTime = exp - getUnixTime()

        return tokenLeftTime < 300
    } catch (e) {
        console.error(e)
        return true
    }
}

export const getUnixTime = () => Math.round(+ new Date() / 1000)

export default Auth.reducer