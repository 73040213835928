import { Box, Button, FormControl, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { addDetail } from "../../store/Details"
import { addMapItem } from "../../store/Parts"

const AddPartForm = ({ parent, type = "section" }) => {

    const dispatch = useDispatch()

    const machineType = useSelector((state) => state.machineType.value)

    const [title, setTitle] = useState("")
    const [code, setCode] = useState("")

    const submit = () => {
        console.log("SUBMIT", title, code, parent, type)

        switch (type) {
            case "section":
                const data = {
                    title: title,
                    code: code,
                    type_id: machineType.id,
                    cords: "0,0"
                }

                if (parent !== null)
                    data['parent'] = parent

                dispatch(addMapItem(data))
                break;
            case "item":
                dispatch(addDetail(parent, {
                    title: title,
                    code: code
                }))
                break;

            default:
                break;
        }
    }

    return (
        <Box>
            <Box sx={{ p: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Добавить
                </Typography>
                <Box>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField
                            id="outlined-basics"
                            label="Название"
                            variant="outlined"
                            value={title}
                            onChange={e => setTitle(e.target.value)} />
                    </FormControl>
                    <FormControl sx={{ mb: 1 }}>
                        <TextField
                            id="outlined-basics"
                            label="Код"
                            variant="outlined"
                            value={code}
                            type='number'
                            onChange={e => setCode(e.target.value)} />
                    </FormControl>
                </Box>
                <Button onClick={submit} variant="contained">Сохранить</Button>
            </Box>
        </Box>
    )
}

export default AddPartForm