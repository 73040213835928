import { Box, Button, List, ListItem, ListItemText, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getModelGroup, getModelGroupitems } from '../../store/ModelGroup'
import Preloaderr from '../../components/Preloaderr'

function ModelGroup() {
    const dispatch = useDispatch()

    const modelGroup = useSelector((state) => state.modelGroup.groups)
    const items = useSelector((state) => state.modelGroup.items)

    useEffect(() => {
        dispatch(getModelGroup())
        dispatch(getModelGroupitems())
    }, [])

    if (modelGroup === null) return <Preloaderr />

    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap'
        }}>
            {
                modelGroup !== null && modelGroup.map((item) => (
                    <Paper sx={{ p: 3, m: '5px' }} key={item.id}>
                        <Typography>{item.title}</Typography>
                        <Box>
                            <List >
                                {
                                    items !== null && items.map(group_item => {
                                        if (group_item.group_id === item.id)
                                            return (
                                                <ListItem>
                                                    <ListItemText primary={group_item.title} />
                                                </ListItem>
                                            )
                                    })
                                }

                            </List>
                        </Box>
                        <Button color='custom_purple'>Добавить</Button>
                    </Paper>
                ))
            }
        </Box>
    )
}

export default ModelGroup