import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateRate } from '../../store/Rate'

function EditRateForm({ item }) {
    const dispatch = useDispatch()
    const [rate, setRate] = useState(item.rate)
    return (
        <Box>
            <Box sx={{ p: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Кратность
                </Typography>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <TextField
                        type='number'
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                    />
                    <Button color='custom_purple' variant='contained' sx={{ mt: '15px' }} onClick={() => {
                        dispatch(updateRate(item.id, rate, item.article))
                    }}>Сохранить</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default EditRateForm