import { Box } from "@mui/material"
import { useSelector } from "react-redux"

const Preloader = () => {

    const preloader = useSelector((state) => state.preloader.value)

    return (
        <>
            {preloader ? <Box>Loading</Box> : null}
        </>
    )
}

export default Preloader