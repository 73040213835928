import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api"
import { toast } from 'react-toastify'

export const mapModels = createSlice({
    name: 'mapModels',
    initialState: {
        map: [],
        mapParent: []
    },
    reducers: {
        setMap: (state, action) => {
            state.map = action.payload
        },
        setMapParent: (state, action) => {
            state.mapParent = action.payload
        }
    },
})

export const { setMap, setMapParent } = mapModels.actions

export const getMapModels = () => async (dispatch) => {
    try {
        const response = await api.get(`/api/v1/shop/model`, {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setMap(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const getMapModelsParent = (id) => async (dispatch) => {
    try {
        const response = await api.get(`/api/v1/shop/model?parent_id=${id}`, {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setMapParent(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const uploadCover = (id, file, parent_id = 0) => async (dispatch) => {
    try {
        const data = new FormData()
        data.append('files', file)
        const response = await toast.promise(
            api.post(`/api/v1/shop/model/${id}/cover`, data),
            {
                pending: 'Загрузка',
                success: 'Изображение успешно загружено 👌',
                error: 'Не удалось выполнить операцию 🤯'
            }
        );

        if (response.status === 201) {
            if (parent_id !== 0) {
                dispatch(getMapModelsParent(parent_id))
            } else {
                dispatch(setMap([]))
                dispatch(getMapModels())
            }
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const updateModelsParent = (id, data) => async (dispatch) => {
    try {
        const response = await api.put(`/api/v1/shop/model/${id}`, data)

        if (response.status === 200) {
            const json = await response.data
            toast.success('Привязка обновлена')
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const addModelsParent = (id, data) => async (dispatch) => {
    try {
        const response = await api.post(`/api/v1/shop/model`, data)

        if (response.status === 201) {
            dispatch(getMapModelsParent(id))
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const deleteModelParent = (id, parent_id) => async (dispatch) => {
    try {
        const response = await api.delete(`/api/v1/shop/model/${id}`)

        if (response.status === 200) {
            dispatch(getMapModelsParent(parent_id))
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export default mapModels.reducer