import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DialogAction from "../../components/DialogAction"
import Modal from "../../components/Modal"
import Confirm from "../../components/Modal/Confirm"
import { deleteDetail, updateTitleDetail } from "../../store/Details"
import { getModels } from "../../store/Models"
import { deleteMapItem, hidden, unhidden, updateTitle } from "../../store/Parts"
import { getModelGroup } from "../../store/ModelGroup"

const EditPartForm = ({ item, close, type = "section" }) => {
    //console.log(item.full_code.split('.'))

    const [title, setTitle] = useState("")
    const [modal, setModal] = useState(false)
    const dispatch = useDispatch();

    const models = useSelector((state) => state.modelGroup.groups)
    const parts = useSelector((state) => state.parts.value)
    const [item_el, setItem] = useState(item)
    const [code, setCode] = useState(item_el.code)


    useEffect(() => {
        if (item_el) {
            setTitle(item_el.title)
            if (type === 'section')
                dispatch(getModelGroup(item.machine_type_id))
        }
    }, [item_el])

    useEffect(() => {
        if (parts && item_el && type === 'section') {
            const map = parts.find(map => map.id === item_el.id)
            if (map) setItem(map)
        }
    }, [parts, item_el])

    const submitEdit = () => {
        console.log("SUBMIT", title, item_el, type)
        switch (type) {
            case "section":
                dispatch(updateTitle(item_el.id, {
                    title,
                    code
                }, item_el.machine_type_id))
                break;

            case "item":
                var full_code = item.full_code.split('.')
                full_code[full_code.length-1] = code
                dispatch(updateTitleDetail(item_el.id, {
                    title,
                    code,
                    full_code: full_code.join(".")
                }, item_el.part_id))
                break;

            default:
                break;
        }

        close()
    }

    const handlerModalConfirm = () => {
        console.log("Delete", item_el.id)
        switch (type) {
            case "section":
                dispatch(deleteMapItem(item_el.id, item_el.machine_type_id))
                break;

            case "item":
                dispatch(deleteDetail(item_el.id, item_el.part_id))
                break;

            default:
                break;
        }
        close()
    }

    return (
        <>
            <Box>
                <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Редактировать
                    </Typography>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField
                            label="Название"
                            variant="outlined"
                            value={title}
                            onChange={e => setTitle(e.target.value)} />
                        <TextField
                            margin="dense"
                            label="Код"
                            variant="outlined"
                            value={code}
                            onChange={e => setCode(e.target.value)} />
                    </FormControl>
                    <Button onClick={submitEdit} variant="contained">Сохранить</Button>
                </Box>
                <Divider />
                {
                    type === 'section' ? (
                        <>
                            <Box sx={{ padding: '24px' }}>
                                <FormControl fullWidth sx={{ mb: 1 }}>
                                    <TextField
                                        label="Код"
                                        variant="outlined"
                                        value={code}
                                        onChange={e => setCode(e.target.value)}
                                    />
                                </FormControl>
                                <Button onClick={submitEdit} variant="contained">Сохранить</Button>
                            </Box>
                            <Box sx={{ padding: '24px' }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Скрыть на моделях
                                </Typography>
                                <Box sx={{ display: 'flex', width: '92%', flexWrap: 'wrap' }}>
                                    {
                                        models !== null ? models.map(model => {
                                            if (model.deprecated) return null
                                            const check = item_el.hidden_list.find(item => item.type_id === model.id)
                                            return <FormControlLabel key={model.id} sx={{ minWidth: '110px' }} value={model.id} control={<Checkbox checked={check ? true : false} onChange={() => {
                                                check ? dispatch(unhidden(item_el.id, {
                                                    model_id: model.id
                                                }, item_el.machine_type_id)) : dispatch(hidden(item_el.id, {
                                                    model_id: model.id,
                                                    hidden: 1
                                                }, item_el.machine_type_id))
                                            }} />} label={model.title} />
                                        }) : ''
                                    }
                                </Box>
                                <Divider />
                            </Box>
                        </>
                    ) : null
                }
                <Box sx={{ p: 3 }}>
                    <Button variant="contained" color="error" onClick={() => setModal(true)}>Удалить</Button>
                </Box>
            </Box>
            <Modal open={modal} close={setModal}>
                <DialogAction title={"Удалить"} text={type === "section" ? "Вы уверенны что ходите удалить раздел?" : "Вы уверены что хотите удалить детать?"} agree="Удалить" open={modal} setOpen={setModal} action={handlerModalConfirm} timer={true} />
            </Modal>
        </>
    )
}

export default EditPartForm