import { createSlice } from "@reduxjs/toolkit";
import api from "../services/api";
import { toast } from "react-toastify";

export const variantItems = createSlice({
  name: "variantItems",
  initialState: {
    value: null,
    beginCopy: false,
  },
  reducers: {
    setVariantItems: (state, action) => {
      state.value = action.payload;
    },
    setBeginCopy: (state, action) => {
      state.beginCopy = action.payload;
    },
  },
});

export const { setVariantItems, setBeginCopy } = variantItems.actions;

export const getVariantItems = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/api/v1/shop/installation/${id}/item`);

    if (response.status === 200) {
      console.log("load");
      const json = await response.data;
      dispatch(setBeginCopy(false));
      dispatch(setVariantItems(json));
    }
  } catch (e) {
    console.error(e);
  }
  /* setTimeout(() => {
        dispatch(setVariantItems(VarItemsData))
    }, 1000) */
};

export const removeVariantItem = (id, variant_id) => async (dispatch) => {
  try {
    const response = await api.delete(`/api/v1/shop/installation/item/${id}`);

    if (response.status === 200) {
      dispatch(getVariantItems(variant_id));
      toast.success("Элемент успешно удален");
    }
  } catch (e) {
    toast.error("Не удалось выполнить операцию");
    console.error(e);
  }
};

export const copyVariantItem =
  (id, data, variant_id) => async (dispatch, getState) => {
    try {
      const beginCopy = getState().variantItems.beginCopy;
      console.log(beginCopy);
      if (beginCopy === false) {
        dispatch(setBeginCopy(true));
        const response = await api.put(
          `/api/v1/shop/installation/item/${id}`,
          data
        );
        if (response.status === 200) {
          dispatch(getVariantItems(variant_id));
          toast.success("Копирование завершино успешно");
        }
      } else {
        console.log("не успел");
        toast.warning("Слишком быстро не успеваю скопировать");
      }
    } catch (e) {
      dispatch(setBeginCopy(false));
      console.error(e);
    }
  };

export const setCordVariantItem =
  (id, data, variant_id) => async (dispatch) => {
    try {
      const response = await api.put(
        `/api/v1/shop/installation/item/${id}`,
        data
      );

      if (response.status === 200) {
        dispatch(getVariantItems(variant_id));
        toast.success("Координаты успешно обновлены");
      }
    } catch (e) {
      toast.error("Не удалось выполнить операцию");
      console.error(e);
    }
  };

export const updateVariantItem = (id, data, variant_id) => async (dispatch) => {
  try {
    const response = await api.put(
      `/api/v1/shop/installation/item/${id}`,
      data
    );

    if (response.status === 200) {
      dispatch(getVariantItems(variant_id));
      toast.success("Данные успешно обновлены");
    }
  } catch (e) {
    toast.error("Не удалось выполнить операцию");
    console.error(e);
  }
};

export const addVariantItem = (data) => async (dispatch) => {
  try {
    const response = await api.post(`/api/v1/shop/installation/item`, data);

    if (response.status === 201) {
      dispatch(getVariantItems(data.variant_id));
      toast.success("Элемент успешно добавлен в сборку");
    }
  } catch (e) {
    toast.error("Не удалось выполнить операцию");
    console.error(e);
  }
};

export default variantItems.reducer;
