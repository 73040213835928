import { useDispatch, useSelector } from "react-redux"
import { setDetails } from "../../store/Details"
import { setImagerImage, setImagerParts, setImagerUpload } from "../../store/Imager"
import { setPart } from "../../store/Part"
import { setSubpart } from "../../store/SubPart"
import { setSubsubpart } from "../../store/SubSubPart"
import { useEffect } from 'react'
import { Box } from "@mui/system"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import SubParts from "./SubParts"
import { createSearchParams, useLocation, useNavigate } from "react-router-dom"

const Parts = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const parts = useSelector((state) => state.parts.value)
    const part = useSelector((state) => state.part.value)

    const handleChangePart = (id) => {
        const params = {}
        const query = new URLSearchParams(location.search)
        for (const [key, value] of query.entries()) {
            params[key] = value
        }

        params['part'] = id
        const options = {
            pathname: location.pathname,
            search: `?${createSearchParams(params)}`,
        }
        navigate(options, { replace: true })

        const findPart = parts.find(item => item.id === id)
        dispatch(setPart(findPart))

        if (findPart.image !== null) {
            dispatch(setImagerImage(findPart.image))
        } else {
            dispatch(setImagerImage("noimg.jpg"))
        }

        dispatch(setImagerParts(findPart.children))

        dispatch(setImagerUpload(
            {
                entity: 'parts',
                id: findPart.id
            }
        ))

        dispatch(setSubpart(null))
        dispatch(setSubsubpart(null))
        dispatch(setDetails(null))
    }


    useEffect(() => {
        if (part === null) {
            const query = new URLSearchParams(location.search)
            if (query.has('part')) {
                const part = parts.find(item => item.id === parseInt(query.get('part')))

                if (typeof part !== "undefined") {
                    dispatch(setPart(part))
                } else {
                    dispatch(setPart(parts[0]))
                }
            } else {
                dispatch(setPart(parts[0]))
            }
        }
    }, [])

    if (part === null) return null

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <FormControl fullWidth>
                    <InputLabel id="bl-select-label">Блок</InputLabel>
                    <Select
                        labelId="bl-select-label"
                        id="bl-select"
                        value={part.id}
                        label="Блок"
                        onChange={e => handleChangePart(e.target.value)}
                    >
                        {parts.map(item => <MenuItem key={item.id} value={item.id}>{item.code}.{item.title}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
            {part.children !== null ? <SubParts /> : null}
        </>
    )
}

export default Parts