import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, List, ListItem, ListItemText, FormControl, RadioGroup, FormControlLabel, Radio, TextField, Divider } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { addModelsParent, deleteModelParent, getMapModels, getMapModelsParent, updateModelsParent, uploadCover } from '../../store/MapModel'
import InsertPhotoTwoToneIcon from '@mui/icons-material/InsertPhotoTwoTone'
import AddLinkTwoToneIcon from '@mui/icons-material/AddLinkTwoTone';
import Modal from '../../components/Modal'
import { getModelGroup } from '../../store/ModelGroup'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'

function ModelMap() {
    const dispatch = useDispatch()

    const modelGroup = useSelector((state) => state.modelGroup.groups)

    const [expanded, setExpanded] = useState(false)
    const [groupModal, setGroupModal] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [groupModalDefVal, setGroupModalDefVal] = useState(0)
    const [modelMapId, setModelMapId] = useState(0)
    const [parent_id, setParent_id] = useState(0)

    const mapModels = useSelector((state) => state.mapModels.map)
    const mapParent = useSelector((state) => state.mapModels.mapParent)

    useEffect(() => {
        dispatch(getMapModels())
        dispatch(getModelGroup())
    }, [])

    const handleChange = (panel, id) => (event, isExpanded) => {
        setParent_id(id)
        setExpanded(isExpanded ? panel : false);
        dispatch(getMapModelsParent(id))
    }

    const handleSetLink = (model_group_id) => {
        dispatch(updateModelsParent(modelMapId, {
            model_group_id
        }))
    }

    return (
        <>
            <div>
                {
                    mapModels.length !== 0 && mapModels.map((item, i) => (
                        <Accordion key={item.id} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`, item.id)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {item.title}
                                    </Typography>
                                    {
                                        item.path !== null && (
                                            <Box sx={{ mr: 6, marginLeft: 'auto' }}>
                                                <img style={{
                                                    width: '220px',
                                                    height: '120px',
                                                    objectFit: "contain"
                                                }} src={`http://shop.dstapi.ru/${item.path}`} alt='' />
                                            </Box>
                                        )
                                    }
                                    <Box>
                                        <IconButton color="primary" title='Добавить обложку' component="label">
                                            <InsertPhotoTwoToneIcon />
                                            <input hidden accept="image/*" type="file" onChange={e => { dispatch(uploadCover(item.id, e.target.files[0])) }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Divider />
                                <List>
                                    {
                                        mapParent.length !== 0 && mapParent.map(parentItem => (
                                            <ListItem key={parentItem.id} secondaryAction={
                                                <>
                                                    <IconButton color="primary" title='Добавить обложку' component="label">
                                                        <InsertPhotoTwoToneIcon />
                                                        <input hidden accept="image/*" type="file" onChange={e => { dispatch(uploadCover(parentItem.id, e.target.files[0], item.id)) }} />
                                                    </IconButton>
                                                    {
                                                        parentItem.gen !== 1 && (
                                                            <IconButton color="warning" title='Прикрепить группу' onClick={() => {
                                                                setGroupModalDefVal(parentItem.model_group_id)
                                                                setModelMapId(parentItem.id)
                                                                setGroupModal(true)
                                                            }}>
                                                                <AddLinkTwoToneIcon />
                                                            </IconButton>
                                                        )
                                                    }
                                                    <IconButton color="error" title='Добавить обложку' onClick={() => {
                                                        dispatch(deleteModelParent(parentItem.id, item.id))
                                                    }}>
                                                        <DeleteForeverTwoToneIcon />
                                                    </IconButton>
                                                </>
                                            }>
                                                <ListItemText primary={`${parentItem.title} поколение ${parentItem.gen}`} />
                                                {
                                                    parentItem.path !== null && (
                                                        <Box sx={{ mr: 6 }}>
                                                            <img style={{
                                                                width: '220px',
                                                                height: '120px',
                                                                objectFit: "contain"
                                                            }} src={`http://shop.dstapi.ru/${parentItem.path}`} alt='' />
                                                        </Box>
                                                    )
                                                }
                                            </ListItem>
                                        ))
                                    }
                                    <Button color='custom_purple' onClick={() => setAddModal(true)}>Добавить</Button>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </div>
            <Modal open={groupModal} close={setGroupModal}>
                <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2, mr: 3 }}>
                        Привязка группы
                    </Typography>
                    <Box sx={{
                        mb: 2
                    }}>
                        <FormControl>
                            <RadioGroup defaultValue={groupModalDefVal} onClick={e => {
                                if (typeof e.target.value !== "undefined") handleSetLink(e.target.value)
                            }}>
                                {
                                    modelGroup !== null && modelGroup.map(item => (
                                        <FormControlLabel key={item.id} value={item.id} control={<Radio color='custom_purple' />} label={item.title} />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
            </Modal>
            <Modal open={addModal} close={setAddModal}>
                <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2, mr: 3 }}>
                        Добавить
                    </Typography>
                    <Box sx={{
                        mb: 2
                    }}>
                        <FormControl component="form" onSubmit={e => {
                            e.preventDefault()
                            const title = e.target.elements.title.value
                            const gen = e.target.elements.gen.value

                            dispatch(addModelsParent(parent_id, {
                                title,
                                gen,
                                parent_id
                            }))

                            setAddModal(false)
                        }}>
                            <TextField
                                required
                                label="Название"
                                variant="outlined"
                                type='text'
                                margin="dense"
                                name='title'
                            />
                            <TextField
                                required
                                label="Поколение"
                                variant="outlined"
                                type='number'
                                margin="dense"
                                name='gen'
                            />
                            <Button color='custom_purple' sx={{ mt: 2 }} type='submit' variant="contained">Добавить</Button>
                        </FormControl>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default ModelMap