import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api"
import { toast } from 'react-toastify'

export const searchItem = createSlice({
    name: 'searchItem',
    initialState: {
        value: null,
        search: false
    },
    reducers: {
        setSearchItem: (state, action) => {
            state.value = action.payload
        },
        setSearch: (state, action) => {
            state.search = action.payload
        },
    },
})

export const { setSearchItem } = searchItem.actions
export const { setSearch } = searchItem.actions

export const getSearchItem = (article) => async (dispatch) => {
    try {
        const response = await api.get(`/api/v1/shop/item/serch?article=${article}`, {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setSearchItem(json))
            dispatch(setSearch(false))
        }

    } catch (e) {
        dispatch(setSearch('ERROR'))
        console.error(e)
    }
}

export default searchItem.reducer