import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
} from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModels, clearModels, setModelId } from "../../store/Models";
import { getModelGroup } from "../../store/ModelGroup";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

const ModelsSelector = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const machineType = useSelector((state) => state.machineType.value);
  const model = useSelector((state) => state.models.model);
  const modelGroup = useSelector((state) => state.modelGroup.groups);

  useEffect(() => {
    if (machineType !== null) {
      dispatch(getModelGroup(machineType.id));
    }
  }, [machineType]);

  useEffect(() => {
    if (modelGroup !== null && modelGroup.length !== 0 && model === 0) {
      const query = new URLSearchParams(location.search);
      if (query.has("model")) {
        const model = modelGroup.find(
          (item) => item.id === parseInt(query.get("model"))
        );

        if (typeof model !== "undefined") {
          dispatch(setModelId(model.id));
        } else {
          dispatch(setModelId(modelGroup[0].id));
        }
      } else {
        dispatch(setModelId(modelGroup[0].id));
      }
    }
  }, [modelGroup]);

  if (modelGroup === null) return null;

  return (
    <Box>
      <RadioGroup
        name="radio-buttons-group"
        value={model}
        onChange={(e) => {
          const params = {};
          const query = new URLSearchParams(location.search);
          for (const [key, value] of query.entries()) {
            params[key] = value;
          }

          params["model"] = e.target.value;
          const options = {
            pathname: location.pathname,
            search: `?${createSearchParams(params)}`,
          };
          navigate(options, { replace: true });

          dispatch(setModelId(e.target.value));
        }}
      >
        <>
          {modelGroup.map((item, i) =>
            !item.deprecated ? (
              <FormControlLabel
                key={item.id}
                value={item.id}
                control={<Radio color="custom_purple" />}
                label={item.title}
              />
            ) : null
          )}
          <FormControlLabel
            key={0}
            value={0}
            control={<Radio color="custom_purple" />}
            label="Без привязки"
          />
        </>
      </RadioGroup>
    </Box>
  );
};

export default ModelsSelector;
