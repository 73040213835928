import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './store/store';
import { BrowserRouter } from 'react-router-dom'
import setupInterceptors from "./services/setupInterceptors"

const root = ReactDOM.createRoot(document.getElementById('root'));

/* root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
); */

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

setupInterceptors(store)