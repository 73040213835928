import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api"
import { toast } from 'react-toastify'

export const variant = createSlice({
    name: 'variant',
    initialState: {
        value: null,
    },
    reducers: {
        setVariant: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setVariant } = variant.actions

export const getVariant = (id) => async (dispatch) => {
    try {
        const response = await api.get(`/api/v1/shop/installation/${id}`, {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setVariant(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const updateImageVariant = (id, file) => async (dispatch) => {
    try {
        const data = new FormData()
        data.append('files', file);

        const response = await toast.promise(
            api.post(`/api/v1/shop/installation/${id}/cover`, data),
            {
                pending: 'Загрузка изображения',
                success: 'Изображение обновлено 👌',
                error: 'Не удалось обновить изображение 🤯'
            }
        )

        if (response.status === 201) {
            dispatch(getVariant(id))
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export default variant.reducer