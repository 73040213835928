import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api"
import { toast } from 'react-toastify'

export const details = createSlice({
    name: 'details',
    initialState: {
        value: null,
    },
    reducers: {
        setDetails: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setDetails } = details.actions

export const getDetails = (parent) => async (dispatch) => {
    try {
        const response = await api.get(`/api/v1/shop/map/${parent}/part`, {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setDetails(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const addDetail = (parent, data) => async (dispatch) => {
    try {
        const response = await api.post(`/api/v1/shop/map/${parent}/part`, data)

        if (response.status === 201) {
            toast.success('Деталь успешно добавлена')
            dispatch(getDetails(parent))
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const deleteDetail = (id, parent) => async (dispatch) => {
    try {
        const response = await api.delete(`/api/v1/shop/part/${id}`)

        if (response.status === 200) {
            toast.success('Деталь успешно удалена')
            dispatch(getDetails(parent))
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const updateTitleDetail = (id, data, parent) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/shop/part/${id}`, data)

        if (response.status === 200) {
            dispatch(getDetails(parent))
            toast.success('Заголовок/Код успешно обновлен')
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export default details.reducer