import { Box, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, MenuItem } from "@mui/material";
import { copyVariantItem, setCordVariantItem } from "../../store/VariantItems";
import { updateImageVariant } from "../../store/Variant";
import { toast } from "react-toastify";

const VariantImage = () => {
  const variant = useSelector((state) => state.variant.value);
  const variantItems = useSelector((state) => state.variantItems.value);
  const dispatch = useDispatch();

  const Point = ({ id, item, number, index, cords }) => {
    const ref = useRef();
    const block = document.getElementById("scheme");

    const [coords, setCoords] = useState([0, 0]);

    useEffect(() => {
      const x = (item.x * 800) / 100;
      const y = (item.y * 800) / 100;

      setCoords([x + "px", y + "px"]);
    }, []);

    const savePosition = (number, left, top) => {
      if (left < 0) left = 0;
      if (top < 0) top = 0;

      if (left > 800) left = 800;
      if (top > 800) top = 800;

      left = left / (800 / 100);
      top = top / (800 / 100);

      const cordsItem = cords.filter((item, i) => i !== index);
      dispatch(
        setCordVariantItem(
          id,
          {
            cords: [
              ...cordsItem,
              {
                x: left,
                y: top,
              },
            ],
          },
          variant.id
        )
      );
    };

    const handler = (e) => {
      if (e.nativeEvent.button === 0 && e.detail === 1) {
        function move(e) {
          const X =
            e.pageX - block.offsetLeft - 20 + ref.current.offsetWidth / 2;
          const Y =
            e.pageY - block.offsetTop - 70 - ref.current.offsetHeight / 2;
          if (X < 800 && X > 0 && Y < 800 && Y > 0) {
            setCoords([X + "px", Y + "px"]);
          } else {
            document.onmousemove = null;
            ref.current.onmouseup = null;
          }
        }

        document.onmousemove = function (e) {
          move(e);
        };

        ref.current.onmouseup = function (e) {
          document.onmousemove = null;
          ref.current.onmouseup = null;

          savePosition(number, ref.current.offsetLeft, ref.current.offsetTop);
        };
      }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const copyDot = () => {
      const totalCords = {
        cords: [
          ...cords,
          {
            x: 5,
            y: 5,
          },
        ],
      };

      if (totalCords.length !== cords) {
        dispatch(copyVariantItem(id, totalCords, variant.id));
        //toast.warning("ecgt[");
      } else {
        toast.error("Не удалось выполнить копирование");
      }
    };

    const deleteDot = () => {
      if (cords.length > 1) {
        const cordsItem = cords.filter((item, i) => i !== index);
        dispatch(
          setCordVariantItem(
            id,
            {
              cords: cordsItem,
            },
            variant.id
          )
        );
      }
    };

    return (
      <Box>
        <div
          ref={ref}
          onMouseDown={handler}
          onContextMenu={(e) => {
            e.preventDefault();
            handleClick(e);
          }}
          onDoubleClick={copyDot}
          data-id={item.id}
          style={{
            backgroundColor: "#5955b3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "22px",
            height: "22px",
            borderRadius: 4,
            color: "#fff",
            cursor: "pointer",
            userSelect: "none",
            fontSize: 12,
            position: "absolute",
            top: coords[1],
            left: coords[0],
          }}
        >
          {number}
        </div>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
        >
          <MenuItem onClick={copyDot}>Копия</MenuItem>
          <MenuItem onClick={deleteDot}>Удалить</MenuItem>
        </Menu>
      </Box>
    );
  };

  const uploadHandler = (file) => {
    dispatch(updateImageVariant(variant.id, file));
  };

  const Points = ({ item }) => {
    return (
      <>
        {item.cords.map((point, i) => (
          <Point
            key={i}
            item={point}
            id={item.id}
            number={item.nomer}
            cords={item.cords}
            index={i}
          />
        ))}
      </>
    );
  };

  return (
    <Box sx={{ flexDirection: "column" }}>
      <Button
        color="custom_purple"
        variant="contained"
        component="label"
        sx={{ mt: "8px" }}
      >
        Изменить иллюстрацию
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={(e) => uploadHandler(e.target.files[0])}
        />
      </Button>
      <Box id="scheme" sx={{ width: 800, mr: 4, position: "relative" }}>
        <img
          style={{ width: 800 }}
          src={`http://shop.dstapi.ru/${variant.image}`}
          alt={variant.title}
        />
        {variantItems !== null
          ? variantItems.map((item, i) => <Points key={i} item={item} />)
          : null}
      </Box>
    </Box>
  );
};

export default VariantImage;
