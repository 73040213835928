import { createSlice } from '@reduxjs/toolkit'
import { setImagerParts } from './Imager'
import api from "../services/api"
import { toast } from 'react-toastify'
import { getMachineTypes } from './MachineTypes'

export const parts = createSlice({
    name: 'parts',
    initialState: {
        value: [],
    },
    reducers: {
        setParts: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setParts } = parts.actions

export const getParts = (type) => async (dispatch, getState) => {
    try {
        const part = getState().part.value
        const subpart = getState().subpart.value
        const subsubpart = getState().subsubpart.value

        const response = await api.get(`/api/v1/shop/map?type_id=${type}`, {})

        if (response.status === 200) {
            const json = await response.data
            dispatch(setParts(json))

            let parts = []
            json.forEach(item => {
                parts.push(
                    {
                        id: item.id,
                        title: item.title,
                        button_cords: item.button_cords
                    }
                )
            })

            if (part === null || subpart !== null || subsubpart !== null) {
                dispatch(setImagerParts(parts))
            }
        }

    } catch (e) {
        console.error(e)
    }
}

export const deleteMapItem = (id, type_id) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/shop/map/${id}`)

        if (response.status === 200) {
            dispatch(getParts(type_id))
            toast.success('Успешно удалено')
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const uploadCoverMap = (id, files, type_id) => async (dispatch, getState) => {
    try {
        const data = new FormData()
        data.append('files', files)
        const response = await api.post(`/api/v1/shop/map/${id}/cover`, data)

        if (response.status === 201) {
            toast.success('Изображение успешно загружено')
            dispatch(getParts(type_id))
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const updateCord = (id, data, type_id) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/shop/map/${id}`, data)

        if (response.status === 200) {
            dispatch(getParts(type_id))
            toast.success('Координаты успешно обновлены')
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const updateTitle = (id, data, type_id) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/shop/map/${id}`, data)

        if (response.status === 200) {
            dispatch(getParts(type_id))
            toast.success('Заголовок успешно обновлен')
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const delCover = (id, type_id, type) => async (dispatch, getState) => {
    try {
        if(type === 'machineType') {
        const response = await api.put(`/api/v1/types/${id}`, {
            image: ''
        })

        if (response.status === 200) {
            dispatch(getMachineTypes())
            toast.success('Удаленно')
        }
    } else {
        const response = await api.put(`/api/v1/shop/map/${id}`, {
            image: ''
        })

        if (response.status === 200) {
            dispatch(getParts(type_id))
            toast.success('Удаленно')
        }
    }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const addMapItem = (data) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/shop/map`, data)

        if (response.status === 201) {
            dispatch(getParts(data.type_id))
            data.parent === null ?
                toast.success('Блок успешно добавлен') :
                toast.success('Раздел успешно добавлен')
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const hidden = (id, data, type_id) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/shop/map/${id}/hidden`, data)

        if (response.status === 201) {
            dispatch(getParts(type_id))
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const unhidden = (id, data, type_id) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/shop/map/${id}/hidden`, { data: data })

        if (response.status === 200) {
            dispatch(getParts(type_id))
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export default parts.reducer