import { createSlice } from '@reduxjs/toolkit'

export const subsubpart = createSlice({
    name: 'subsubpart',
    initialState: {
        value: null,
    },
    reducers: {
        setSubsubpart: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setSubsubpart } = subsubpart.actions

export default subsubpart.reducer