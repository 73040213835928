import { createSlice } from '@reduxjs/toolkit'

export const part = createSlice({
    name: 'part',
    initialState: {
        value: null,
    },
    reducers: {
        setPart: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setPart } = part.actions

export default part.reducer