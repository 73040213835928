import { createSlice } from '@reduxjs/toolkit'

export const preloader = createSlice({
    name: 'preloader',
    initialState: {
        value: false,
    },
    reducers: {
        setPreloader: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setPreloader } = preloader.actions

export default preloader.reducer