import { createSlice } from '@reduxjs/toolkit'

export const machineType = createSlice({
    name: 'machineType',
    initialState: {
        value: null,
    },
    reducers: {
        setMachineType: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setMachineType } = machineType.actions

export default machineType.reducer