import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api"
import { toast } from 'react-toastify'

export const rate = createSlice({
    name: 'rate',
    initialState: {
        items: null,
    },
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload
        },
    },
})

export const get1cItems = (article) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/v1/shop/load/1c?article=${article}`, {})

        if (response.status === 200) {
            const json = await response.data
            if (json.length === 0) toast.error('Не найдено')
            dispatch(setItems(json))
        }

    } catch (e) {
        toast.error('Не найдено')
        console.error(e)
    }
}

export const sync1cItems = () => async (dispatch, getState) => {
    try {
        await toast.promise(
            api.post(`/api/v1/shop/load/1c`, {}),
            {
                pending: 'Синхронизация',
                success: 'Успешно синхронизировано 👌',
                error: 'Не удалось синхронизировать 🤯'
            }
        );
        /* const response = await api.post(`/api/v1/shop/load/1c`, {})

        if (response.status === 201) {
        } */

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const updateRate = (id, rate, article) => async (dispatch, getState) => {
    try {
        const response = await api.put(`/api/v1/shop/load/1c/${id}`, { rate })

        if (response.status === 200) {
            dispatch(get1cItems(article))
            toast.success('Кратность успешно обновлена')
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const delete1Item = (id, article) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/v1/shop/load/1c/${id}`, { rate })

        if (response.status === 200) {
            dispatch(get1cItems(article))
            toast.success('Запись успешно удалена')
        }

    } catch (e) {
        toast.error('Не удалось выполнить операцию')
        console.error(e)
    }
}

export const { setItems } = rate.actions

export default rate.reducer