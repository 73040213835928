import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDetails } from "../../store/Details";
import {
  setImagerImage,
  setImagerParts,
  setImagerUpload,
} from "../../store/Imager";
import { setSubpart } from "../../store/SubPart";
import { setSubsubpart } from "../../store/SubSubPart";
import SubSubPartsMap from "./SubSubPartsMap";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Modal from "../../components/Modal";
import EditPartForm from "./EditPartForm";
import { useEffect, useState } from "react";
import AddPartForm from "./AddPartForm";
import { useLocation, useSearchParams } from "react-router-dom";

const SubPartsMap = ({ items = null, parentCode }) => {
  const dispatch = useDispatch();

  const part = useSelector((state) => state.part.value);
  const subpart = useSelector((state) => state.subpart.value);
  const machineType = useSelector((state) => state.machineType.value);
  const subsubpart = useSelector((state) => state.subsubpart.value);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  const [itemEdit, setItemEdit] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handlerEdit = (e, item) => {
    e.stopPropagation();
    setOpen(true);
    setItemEdit(item);
  };

  const [modal, setModal] = useState(false);

  const handlerModalClose = () => {
    setModal(false);
  };

  useEffect(() => {
    if (items === null || items.length === 0) return;
    const query = new URLSearchParams(location.search);
    if (query.has("subpart")) {
      const qSubPart = items.find(
        (item) => item.id === parseInt(query.get("subpart"))
      );
      console.log(qSubPart);
      if (typeof qSubPart !== "undefined") {
        handlerSubPart(
          qSubPart,
          !query.has("subsubpart") || subsubpart === null ? true : false
        );
      } else {
        handlerSubPart(items[0]);
      }
    } else {
      if (part !== null && subpart !== null) {
        const item = items.find((item) => item.id === subpart.id);
        if (typeof item !== "undefined") {
          handlerSubPart(
            item,
            !query.has("subsubpart") || subsubpart === null ? true : false
          );
        } else {
          handlerSubPart(items[0]);
        }
      }
    }
  }, [items]);

  const handlerSubPart = (item, refreshParams = true, isClick = false) => {
    if (isClick && subpart && item.id === subpart.id) {
      dispatch(setSubpart(null));

      if (part.image !== null) {
        dispatch(setImagerImage(part.image));
      } else {
        dispatch(setImagerImage("noimg.jpg"));
      }

      dispatch(setImagerParts(part.children));

      dispatch(
        setImagerUpload({
          entity: "parts",
          id: item.id,
          type_id: machineType.id,
        })
      );
      setSearchParams({
        machine_type: machineType.id,
        part: part.id,
      });
    } else {
      dispatch(setSubpart(item));

      if (refreshParams) {
        if (item.image !== null) {
          dispatch(setImagerImage(item.image));
        } else {
          dispatch(setImagerImage("noimg.jpg"));
        }

        dispatch(setImagerParts(item.children));

        dispatch(
          setImagerUpload({
            entity: "parts",
            id: item.id,
            type_id: machineType.id,
          })
        );
        setSearchParams({
          machine_type: machineType.id,
          part: part.id,
          subpart: item.id,
        });
      }
    }

    dispatch(setSubsubpart(null));
    dispatch(setDetails(null));
  };

  return (
    <>
      <Box sx={{ pl: 3, py: 1 }}>
        {items !== null
          ? items.map((item) => (
              <Box key={item.id}>
                <ListItem
                  disablePadding
                  sx={{ borderLeft: "1px solid #dedede" }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="comment"
                      onClick={(e) => handlerEdit(e, item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  }
                  onClick={() => handlerSubPart(item, true, true)}
                >
                  <ListItemButton>
                    <ListItemText
                      primary={
                        <Box display={"flex"}>
                          <Box sx={{ mr: 1 }}>
                            {parentCode}.{item.code}.
                          </Box>
                          <Box
                            sx={{
                              cursor: "pointer",
                              fontWeight:
                                subpart && item.id === subpart.id ? 600 : 400,
                            }}
                          >
                            {item.title}
                          </Box>
                        </Box>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                {subpart && item.id === subpart.id ? (
                  <SubSubPartsMap
                    items={item.children}
                    parentCode={`${parentCode}.${item.code}`}
                  />
                ) : null}
              </Box>
            ))
          : null}
        <ListItem sx={{ borderLeft: "1px solid #dedede" }} disablePadding>
          <ListItemButton onClick={() => setModal(true)}>
            <AddCircleOutlineIcon
              color="primary"
              sx={{ mr: 1 }}
              fontSize="small"
            />
            <ListItemText secondary="Добавить раздел" />
          </ListItemButton>
        </ListItem>
      </Box>

      <Modal open={open} close={handleClose}>
        <EditPartForm item={itemEdit} close={handleClose} />
      </Modal>

      <Modal open={modal} close={handlerModalClose}>
        <AddPartForm parent={part.id} />
      </Modal>
    </>
  );
};

export default SubPartsMap;
