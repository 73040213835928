import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setDetails } from "../../store/Details"
import { setImagerImage, setImagerParts, setImagerUpload } from "../../store/Imager"
import { setMachineType } from "../../store/MachineType"
import { getMachineTypes } from "../../store/MachineTypes"
import { clearModels, setModelId } from "../../store/Models"
import { setPart } from "../../store/Part"
import { getParts, setParts } from "../../store/Parts"
import { setSubpart } from "../../store/SubPart"
import { setSubsubpart } from "../../store/SubSubPart"
import Parts from "./Parts"
import { useLocation, useNavigate, createSearchParams } from "react-router-dom"

const PartSelector = () => {

    const dispatch = useDispatch()

    const location = useLocation()
    const navigate = useNavigate()

    const machineTypes = useSelector((state) => state.machineTypes.value)
    const machineType = useSelector((state) => state.machineType.value)
    const parts = useSelector((state) => state.parts.value)

    useEffect(() => {
        dispatch(getMachineTypes())
    }, [])

    const handleChange = (id) => {
        const params = {}
        const query = new URLSearchParams(location.search)
        for (const [key, value] of query.entries()) {
            params[key] = value
        }

        params['machine_type'] = id
        const options = {
            pathname: location.pathname,
            search: `?${createSearchParams(params)}`,
        }
        navigate(options, { replace: true })

        const type = machineTypes.find(item => item.id === id)
        dispatch(setParts([]))
        dispatch(setImagerParts([]))
        dispatch(setMachineType(type))
        dispatch(getParts(type.id))
        dispatch(setPart(null))
        dispatch(setSubpart(null))
        dispatch(setSubsubpart(null))
        dispatch(setDetails(null))
        dispatch(clearModels())
        dispatch(setModelId(0))

        if (type.image !== null) {
            dispatch(setImagerImage(type.image))
        } else {
            dispatch(setImagerImage("noimg.jpg"))
        }

        dispatch(setImagerUpload(
            {
                entity: 'machineType',
                id: type.id
            }
        ))
    }

    useEffect(() => {
        console.log(machineType)
        if (machineTypes.length > 0 && machineType === null) {
            const query = new URLSearchParams(location.search)
            if (query.has('machine_type')) {
                const type = machineTypes.find(item => item.id === parseInt(query.get('machine_type')))

                if (typeof type !== "undefined") {
                    handleChange(type.id)
                } else {
                    handleChange(machineTypes[0].id)
                }
            } else {
                handleChange(machineTypes[0].id)
            }
        } else if (machineType !== null) {
            handleChange(machineType.id)
        }
    }, [machineTypes.length, machineTypes, machineType])


    if (machineType === null) return null

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <FormControl fullWidth>
                    <InputLabel id="mt-select-label">Тип машин</InputLabel>
                    <Select
                        labelId="mt-select-label"
                        id="mt-select"
                        value={machineType.id}
                        label="Тип машин"
                        onChange={e => handleChange(e.target.value)}
                    >
                        {machineTypes.map(item => <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
            {parts.length > 0 ? <Parts /> : null}
        </>
    )
}

export default PartSelector