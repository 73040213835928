import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDetails } from "../../store/Details"
import { setImagerImage, setImagerParts, setImagerUpload } from "../../store/Imager"
import { setSubsubpart } from "../../store/SubSubPart"
import { Box } from "@mui/system"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { createSearchParams, useLocation, useNavigate } from "react-router-dom"

const SubSubParts = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const subpart = useSelector((state) => state.subpart.value)
    const subsubpart = useSelector((state) => state.subsubpart.value)

    const handleChangeSubSub = (id) => {
        const params = {}
        const query = new URLSearchParams(location.search)
        for (const [key, value] of query.entries()) {
            params[key] = value
        }

        params['subsubpart'] = id
        const options = {
            pathname: location.pathname,
            search: `?${createSearchParams(params)}`,
        }
        navigate(options, { replace: true })

        const findPart = subpart.children.find(item => item.id === id)

        dispatch(setSubsubpart(findPart))
        dispatch(setImagerImage(null))
        dispatch(setImagerParts([]))
        dispatch(setImagerUpload())
        dispatch(getDetails(id))
    }

    useEffect(() => {
        if (subsubpart === null && subpart.children.length > 0) {
            const query = new URLSearchParams(location.search)
            if (query.has('subsubpart')) {
                const subsubpart = subpart.children.find(item => item.id === parseInt(query.get('subsubpart')))

                if (typeof subsubpart !== "undefined") {
                    handleChangeSubSub(subsubpart.id)
                } else {
                    handleChangeSubSub(subpart.children[0].id)
                }
            } else {
                handleChangeSubSub(subpart.children[0].id)
            }
        }
    }, [subsubpart])

    if (subsubpart === null) return null

    return (
        <>
            <Box>
                <FormControl fullWidth>
                    <InputLabel id="ssp-select-label">Подраздел</InputLabel>
                    <Select
                        labelId="ssp-select-label"
                        id="ssp-select"
                        value={subsubpart.id}
                        label="Подраздел"
                        onChange={e => handleChangeSubSub(e.target.value)}
                    >
                        {subpart.children.map(item => <MenuItem key={item.id} value={item.id}>{item.code}.{item.title}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default SubSubParts