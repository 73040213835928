import { createSlice } from '@reduxjs/toolkit'
import api from '../services/api'

export const machineTypes = createSlice({
    name: 'machineTypes',
    initialState: {
        value: [],
    },
    reducers: {
        setMachineTypes: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setMachineTypes } = machineTypes.actions

export const getMachineTypes = (type) => async (dispatch) => {
    try {
        const response = await api.get(`/api/v1/types`, {})

        if (response.status === 200) {
            const json = await response.data

            json.map(item => {
                if (item.image !== null)
                    item.image = 'https://machine.dstapi.ru/' + item.image

                return item
            })

            dispatch(setMachineTypes(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export default machineTypes.reducer