import { Box, Button, FormControl, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSearchItem, setSearch, setSearchItem } from "../../store/SearchItem"
import { addVariantItem } from "../../store/VariantItems"

const AddOtherItemForm = ({ close }) => {

    const dispatch = useDispatch()

    const [number, setNumber] = useState("")
    const [numberE, setNumberE] = useState(false)
    const [article, setArticle] = useState("")
    const [articleE, setArticleE] = useState(false)
    const [titleThis, setTitleThis] = useState("")
    const [searching, setSearching] = useState(false)

    const variant = useSelector((state) => state.variant.value)
    const searchItem = useSelector((state) => state.searchItem.value)
    const searchProcess = useSelector((state) => state.searchItem.search)

    const submit = () => {
        if (number.trim() === "") {
            setNumberE(true)
        } else {
            setNumberE(false)
        }

        if (article.trim() === "") {
            setArticleE(true)
        } else {
            setArticleE(false)
        }

        if (number.trim() !== "" && article.trim() !== "") {
            console.log("SAVE", number, article, searchItem, titleThis, variant)
            dispatch(addVariantItem({
                'variant_id': variant.id,
                'item_id': searchItem.id,
                'article': article,
                'nomer': number,
                'title': titleThis
            }))
            close()
        }
    }

    const handlerChangeN = (value) => {
        if (value.trim !== "") {
            setNumberE(false)
            setNumber(value)
        }
    }

    const handlerChangeA = (value) => {

        setArticle(value)

        if (value.length === 6) {
            setSearching(true)
            handlerSearch(value)
        } else {
            setSearching(false)
            dispatch(setSearchItem(null))
            dispatch(setSearch(false))
        }
    }

    const handlerSearch = (value) => {
        dispatch(setSearch(true))
        dispatch(getSearchItem(value))
    }

    const Search = () => {
        if (searchProcess === 'ERROR') {
            return (
                <Typography>Не найдено</Typography>
            )
        } else if (searchProcess) {
            return (
                <Typography>Поиск</Typography>
            )
        }
    }

    return (
        <>
            <Box sx={{ p: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Добавить
                </Typography>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                        id="outlined-basics"
                        label="Артикул"
                        required
                        value={article}
                        error={articleE}
                        onChange={e => handlerChangeA(e.target.value)}
                        variant="outlined" />
                </FormControl>

                {searching ? <Search /> : null}

                {searchItem ? <>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField
                            disabled
                            id="outlined-basics"
                            label="Название"
                            value={`${searchItem.fullCode} — ${searchItem.title}`}
                            variant="outlined" />
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField
                            id="outlined-basics"
                            label="Номер на схеме"
                            type={'number'}
                            required
                            value={number}
                            error={numberE}
                            onChange={e => handlerChangeN(e.target.value)}
                            variant="outlined" />
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField
                            id="outlined-basics"
                            label="Название (альтернативное)"
                            value={titleThis}
                            onChange={e => setTitleThis(e.target.value)}
                            variant="outlined" />
                    </FormControl>
                    <Button onClick={submit} variant="contained">Сохранить</Button>
                </> : null}

            </Box>
        </>
    )
}

export default AddOtherItemForm