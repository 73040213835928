import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import Details from './Details'
import Imager from './Imager'
import PartsMap from './PartsMap'
import TypeSelector from './TypeSelector'

const Home = () => {
    const parts = useSelector((state) => state.parts.value)
    const image = useSelector((state) => state.imager.image)
    const details = useSelector((state) => state.details.value)

    return (
        <>
            <TypeSelector />
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <PartsMap parts={parts} />
                </Grid>
                <Grid item xs={7}>
                    {image ? <Imager /> : null}
                    {details ? <Details /> : null}
                </Grid>
            </Grid>
        </>
    )
}

export default Home