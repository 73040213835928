import { Box, Button, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import Modal from "../../components/Modal";
import EditPartForm from "./EditPartForm";
import AddPartForm from "./AddPartForm";
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';

const Details = () => {

    const details = useSelector((state) => state.details.value)
    const subsubpart = useSelector((state) => state.subsubpart.value)

    const [open, setOpen] = useState(false)
    const [itemEdit, setItemEdit] = useState(null)

    const handleClose = () => {
        setOpen(false);
    };

    const handlerEdit = (e, item) => {
        e.stopPropagation()
        setOpen(true);
        setItemEdit(item)
    }

    const [modal, setModal] = useState(false)

    const handlerModalClose = () => {
        setModal(false)
    }

    const Detail = ({ detail }) => {
        return (
            <>
                <ListItem
                    disablePadding
                    secondaryAction={
                        <IconButton edge="end" aria-label="comment" onClick={e => handlerEdit(e, detail)}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    <ListItemButton>
                        <ListItemText primary={`${detail.code}. ${detail.title}`} />
                    </ListItemButton>
                </ListItem>
            </>
        )
    }

    return (
        <>
            <Paper sx={{ px: 3, py: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Список деталей
                </Typography>

                <Box sx={{ width: '100%', height: 400, overflowY: 'scroll', bgcolor: 'background.paper' }}>
                    <List>
                        {details.map(detail => <Detail key={detail.id} detail={detail} />)}
                    </List>
                </Box>
                <Box sx={{ my: 2 }}>
                    <Button onClick={() => setModal(true)} variant="contained">Добавить</Button>
                </Box>
            </Paper>

            <Modal open={open} close={handleClose}>
                <EditPartForm item={itemEdit} close={handleClose} type="item" />
            </Modal>

            <Modal open={modal} close={handlerModalClose}>
                <AddPartForm parent={subsubpart.id} type="item" />
            </Modal>
        </>
    )
}

export default Details