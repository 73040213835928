import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  FormControl,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Switch,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { getModels } from "../../store/Models";
import {
  linkVariant,
  unlinkVariant,
  updateVariant,
} from "../../store/Variants";
import { getModelGroup } from "../../store/ModelGroup";
import DialogAction from "../../components/DialogAction";
import { updateVariantItem } from "../../store/VariantItems";

function EditForm({ setOpen, item_cur }) {
  const dispatch = useDispatch();
  const [item, setItem] = useState(item_cur);
  const [checkDelete, setCheckDelete] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);

  const variants = useSelector((state) => state.variants.value);
  //const models = useSelector((state) => state.models.value)
  const models = useSelector((state) => state.modelGroup.groups);
  const cur_model = useSelector((state) => state.models.model);

  const parts = useSelector((state) => state.parts.value);
  const part = useSelector((state) => state.part.value);
  const subpart = useSelector((state) => state.subpart.value);
  const subsubpart = useSelector((state) => state.subsubpart.value);

  const [updatedPart, setUpdatedPart] = useState(part);
  const [updatedSubpart, setUpdatedSubpart] = useState(subpart);
  const [updatedSubsubpart, setUpdatedSubsubpart] = useState(subsubpart);

  const [isHidden, setHidden] = useState(false);

  const [title, setTitle] = useState("");
  const [article, setArticle] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (item) {
      setTitle(item.title);
      setArticle(item.article);
      setComment(item.comment);
      setHidden(item.hidden);
      dispatch(getModelGroup(item.machine_type_id));
    }
  }, [item]);

  useEffect(() => {
    if (variants && item) {
      const map = variants.find((map) => map.id === item.id);
      if (map) setItem(map);
    }
  }, [variants, item]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = (e) => {
    e.preventDefault();

    dispatch(
      updateVariant(
        item.id,
        {
          title,
          article,
          comment,
        },
        item.part_map_id,
        cur_model
      )
    );

    setOpen(false);
    setTitle("");
  };

  const onCopy = () => {
    dispatch(
      updateVariant(
        item.id,
        {
          map_id: updatedSubsubpart.id,
        },
        item.part_map_id,
        cur_model
      )
    );
  };

  return (
    <Box>
      <DialogTitle>Параметры</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => handleSend(e)} autoComplete="off">
          <Box>
            <FormControl>
              <TextField
                margin="dense"
                label="Название"
                type="text"
                fullWidth
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Артикул"
                type="number"
                fullWidth
                variant="outlined"
                value={article}
                onChange={(e) => setArticle(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Комментарий"
                type="text"
                multiline
                fullWidth
                variant="outlined"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </FormControl>
          </Box>
          <Button
            variant="contained"
            sx={{ mb: "24px", mt: "12px" }}
            type="submit"
          >
            Сохранить
          </Button>
        </form>
        <Divider />
        <FormGroup>
          <FormControlLabel
            control={<Switch defaultChecked={item.hidden ? true : false} />}
            label="Скрыть"
            value={isHidden}
            onClick={() => {
              isHidden
                ? dispatch(
                    updateVariant(
                      item.id,
                      { hidden: 0 },
                      item.part_map_id,
                      cur_model
                    )
                  )
                : dispatch(
                    updateVariant(
                      item.id,
                      { hidden: 1 },
                      item.part_map_id,
                      cur_model
                    )
                  );
            }}
          />
        </FormGroup>
        <Divider />
        <Box sx={{ mt: "24px" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Привязать к моделям
          </Typography>
          <Box sx={{ display: "flex", width: "92%", flexWrap: "wrap" }}>
            <>
              {models.length !== 0
                ? models.map((model) => {
                    if (model.deprecated) return null;
                    const check =
                      item !== null
                        ? item.models.find((item) => item.model_id === model.id)
                        : false;
                    return (
                      <FormControlLabel
                        key={model.id}
                        sx={{ minWidth: "110px" }}
                        value={model.id}
                        control={
                          <Checkbox defaultChecked={check ? true : false} />
                        }
                        label={model.title}
                        onClick={() => {
                          !check
                            ? dispatch(
                                linkVariant(
                                  item.id,
                                  {
                                    model_id: model.id,
                                  },
                                  item.part_map_id,
                                  cur_model
                                )
                              )
                            : setOpenDelete(true);
                          setCheckDelete(check);
                        }}
                      />
                    );
                  })
                : ""}
              <DialogAction
                open={openDelete}
                setOpen={setOpenDelete}
                title="Удаление привзяки"
                text="Вы уверены что хотите удалить связь?"
                agree="Удалить"
                action={() =>
                  dispatch(
                    unlinkVariant(checkDelete.id, item.part_map_id, cur_model)
                  )
                }
              />
            </>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ mt: "24px" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Переместить
          </Typography>
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="bl-select-label">Блок</InputLabel>
              <Select
                labelId="bl-select-label"
                id="bl-select"
                value={updatedPart.id}
                label="Блок"
                onChange={(e) => {
                  const part = parts.filter(
                    (item) => item.id === e.target.value
                  );
                  setUpdatedPart(part[0]);
                  setUpdatedSubpart(
                    part[0].children.length ? part[0].children[0] : []
                  );
                  setUpdatedSubsubpart(
                    part[0].children.length
                      ? part[0].children[0].children.length
                        ? part[0].children[0].children[0]
                        : []
                      : []
                  );
                }}
              >
                {parts.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.code}.{item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {updatedPart.children !== null ? (
            <>
              <Box sx={{ mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="sp-select-label">Раздел</InputLabel>
                  <Select
                    labelId="sp-select-label"
                    id="sp-select"
                    value={updatedSubpart.id}
                    label="Раздел"
                    onChange={(e) => {
                      const subpart = updatedPart.children.filter(
                        (item) => item.id === e.target.value
                      );
                      setUpdatedSubpart(subpart[0]);
                      setUpdatedSubsubpart(
                        subpart[0].children.length ? subpart[0].children[0] : []
                      );
                    }}
                  >
                    {updatedPart.children.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.code}.{item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {updatedSubpart.children !== null ? (
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="ssp-select-label">Подраздел</InputLabel>
                    <Select
                      labelId="ssp-select-label"
                      id="ssp-select"
                      value={updatedSubsubpart.id}
                      label="Подраздел"
                      onChange={(e) => {
                        const subsubpart = updatedSubpart.children.filter(
                          (item) => item.id === e.target.value
                        );
                        setUpdatedSubsubpart(subsubpart[0]);
                      }}
                    >
                      {updatedSubpart.children.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.code}.{item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : null}
            </>
          ) : null}
          <Button
            variant="contained"
            sx={{ mb: "24px", mt: "12px" }}
            onClick={onCopy}
          >
            Сохранить
          </Button>
        </Box>
      </DialogContent>
    </Box>
  );
}

export default EditForm;
