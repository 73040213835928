import { Button } from '@mui/material'
import React from 'react'
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { useDispatch } from 'react-redux';
import { sync1cItems } from '../../store/Rate';

function Sync() {
    const dispatch = useDispatch()
    return (
        <Button variant='contained' color='custom_purple' onClick={() => dispatch(sync1cItems())} endIcon={<CloudSyncIcon />}>Синхронизация</Button>
    )
}

export default Sync