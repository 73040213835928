import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDetails, setDetails } from "../../store/Details";
import { setImagerImage, setImagerParts } from "../../store/Imager";
import { setSubsubpart } from "../../store/SubSubPart";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import EditPartForm from "./EditPartForm";
import AddPartForm from "./AddPartForm";
import { useLocation, useSearchParams } from "react-router-dom";

const SubSubPartsMap = ({ items, parentCode }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const part = useSelector((state) => state.part.value);
  const subpart = useSelector((state) => state.subpart.value);
  const machineType = useSelector((state) => state.machineType.value);
  const subsubpart = useSelector((state) => state.subsubpart.value);

  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  const [itemEdit, setItemEdit] = useState(null);

  useEffect(() => {
    if (items === null || items.length === 0) return;
    const query = new URLSearchParams(location.search);
    if (query.has("subsubpart")) {
      const qSubSubPart = items.find(
        (item) => item.id === parseInt(query.get("subsubpart"))
      );

      if (typeof qSubSubPart !== "undefined") {
        handlerSubSubPart(qSubSubPart);
      } else {
        handlerSubSubPart(items[0]);
      }
    } else {
      if (subsubpart !== null) {
        const item = items.find((item) => item.id === subsubpart.id);
        if (typeof item !== "undefined") {
          handlerSubSubPart(item);
        } else {
          handlerSubSubPart(items[0]);
        }
      }
    }
  }, [items]);

  const handleClose = () => {
    setOpen(false);
  };

  const handlerEdit = (e, item) => {
    e.stopPropagation();
    setOpen(true);
    setItemEdit(item);
  };

  const [modal, setModal] = useState(false);

  const handlerModalClose = () => {
    setModal(false);
  };

  const handlerSubSubPart = (item, isClick = false) => {
    if (isClick && subsubpart && item.id === subsubpart.id) {
      dispatch(setSubsubpart(null));

      if (subpart.image !== null) {
        dispatch(setImagerImage(subpart.image));
      } else {
        dispatch(setImagerImage("noimg.jpg"));
      }

      dispatch(setImagerParts(subpart.children));
      dispatch(setDetails(null));
      setSearchParams({
        machine_type: machineType.id,
        part: part.id,
        subpart: subpart.id,
      });
    } else {
      dispatch(setSubsubpart(item));
      dispatch(setImagerImage(null));
      dispatch(setImagerParts([]));
      dispatch(getDetails(item.id));
      setSearchParams({
        machine_type: machineType.id,
        part: part.id,
        subpart: subpart.id,
        subsubpart: item.id,
      });
    }
  };

  return (
    <>
      <Box sx={{ pl: 3, py: 1 }}>
        {items !== null
          ? items.map((item) => (
              <Box key={item.id}>
                <ListItem
                  sx={{ borderLeft: "1px solid #dedede" }}
                  disablePadding
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="comment"
                      onClick={(e) => handlerEdit(e, item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  }
                  onClick={() => handlerSubSubPart(item, true)}
                >
                  <ListItemButton>
                    <ListItemText
                      primary={
                        <Box display={"flex"}>
                          <Box sx={{ mr: 1 }}>
                            {parentCode}.{item.code}
                          </Box>
                          <Box
                            sx={{
                              cursor: "pointer",
                              fontWeight:
                                subsubpart && item.id === subsubpart.id
                                  ? 600
                                  : 400,
                            }}
                          >
                            {item.title}
                          </Box>
                        </Box>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                {/* {subpart && data.id === subpart.id ? <SubSubPartsMap items={data.children} parentCode={`${parentCode}.${data.code}`} /> : null} */}
              </Box>
            ))
          : null}
        <ListItem sx={{ borderLeft: "1px dotted #dedede" }} disablePadding>
          <ListItemButton onClick={() => setModal(true)}>
            <AddCircleOutlineIcon
              color="primary"
              sx={{ mr: 1 }}
              fontSize="small"
            />
            <ListItemText secondary="Добавить  подраздел" />
          </ListItemButton>
        </ListItem>
      </Box>

      <Modal open={open} close={handleClose}>
        <EditPartForm item={itemEdit} close={handleClose} />
      </Modal>

      <Modal open={modal} close={handlerModalClose}>
        <AddPartForm parent={subpart.id} />
      </Modal>
    </>
  );
};

export default SubSubPartsMap;
