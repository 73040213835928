import { createSlice } from "@reduxjs/toolkit";
import api from '../services/api'

export const modelGroup = createSlice({
    name: 'modelGroup',
    initialState: {
        groups: null,
        items: null
    },
    reducers: {
        setGroups: (state, action) => {
            state.groups = action.payload
        },
        setGroupsItems: (state, action) => {
            state.items = action.payload
        }
    }
})

export const { setGroups, setGroupsItems } = modelGroup.actions

export const getModelGroup = (type_id = 0) => async (dispatch) => {
    try {
        const queryType_id = type_id !== 0 ? `?type_id=${type_id}` : ''
        const response = await api.get(`/api/v1/shop/model/group${queryType_id}`, {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setGroups(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const getModelGroupitems = () => async (dispatch) => {
    try {
        const response = await api.get(`/api/v1/shop/model/group/items`, {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setGroupsItems(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export default modelGroup.reducer