import { Box, Dialog, DialogTitle, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'

const Modal = ({ open, close, children }) => {

    const handleClose = () => {
        close(false);
    };

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <IconButton
                aria-label="close"
                onClick={() => handleClose(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 21,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box sx={{
                minWidth: '200px'
            }}>
                {children}
            </Box>
        </Dialog>
    )
}

export default Modal